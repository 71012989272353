const SELECT_EXPORT_TYPES = Object.freeze({ periodo: 1, rango: 2 });

const EXCEL_TYPES = Object.freeze({
  clientes: 1,
  proveedores: 2,
  po: 3,
  recursosPo: 4,
  recursosGeneral: 5,
  cecosGeneral: 6,
});

export { SELECT_EXPORT_TYPES, EXCEL_TYPES };
