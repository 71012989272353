import React, { useContext } from "react";
import { Card } from "primereact/card";
import { Button } from "primereact/button";
import { SplitButton } from "primereact/splitbutton";
import { CotizadorContext } from "../../../contexts/CotizadorContext";
import { AuthContext } from "../../../contexts/AuthContext";
import { fechaHora } from "../../../helpers";
import "./DetalleDeCotizacion.css";
import { useNavigate } from "react-router-dom";

const DetalleDeCotizacion = () => {
  const {
    getExcelCotizacion,
    getPdfCotizacion,
    datosProyectos,
    dataTablaTalento,
    selectedTipoProyecto,
    dataCalculoTalento,
    totalOtrosCostos,
    version,
    postCotizaciones,
    idCotizacion,
    putCotizaciones,
    tipoMoneda,
  } = useContext(CotizadorContext);
  const { username } = useContext(AuthContext);

  const obtenerMeses = (name) => {
    const match = name?.match(/\d+/);
    return match ? parseInt(match[0], 10) : 0;
  };
  const navigate = useNavigate();

  const selectedMoneda = tipoMoneda.find(
    (m) => m.value === datosProyectos.moneda
  );
  const price = selectedMoneda ? selectedMoneda.price : 1;
  const simboloMoneda = selectedMoneda ? selectedMoneda.value : "$";

  const talento = dataTablaTalento?.map((talento) => talento);
  const teamData = talento.reduce(
    (acc, item) => {
      acc.amount += item.cantidad;
      return acc;
    },
    { amount: 0 }
  );

  const getTeamDataPropertyName = (tipoProyecto) => {
    switch (tipoProyecto) {
      case "Contractor":
        return "teamDataContractor";
      case "Tarifa":
        return "teamDataTarifa";
      default:
        return "teamData";
    }
  };

  const cotizacion = {
    version: version,
    currency: datosProyectos.moneda,
    type: datosProyectos.tipoProyecto,
    projectData: {
      firstTable: {
        date: fechaHora().fecha,
        [idCotizacion ? "idUser" : "user"]: idCotizacion
          ? dataCalculoTalento.idUser
          : username,
        client: datosProyectos.cliente?.name || datosProyectos.cliente,
        projectName: datosProyectos.nombreOportunidad,
        projectType: datosProyectos.tipoProyecto,
        hours: datosProyectos.horas?.toString() || null,
        duration: datosProyectos.duracion.name,
        paymentTerms: datosProyectos.formaPago.name,
        grossIncomeTax: datosProyectos.impuesto.name,
        LEC: datosProyectos.tieneLEC === "si" ? true : false,
        currency:
          datosProyectos.moneda === "$"
            ? "Peso argentino"
            : datosProyectos.moneda,
      },
      secondTable: {
        overallProfitability:
          "$" + dataCalculoTalento.rentabilidadTotal?.toLocaleString() || null,
        monthlyProfit:
          "$" + dataCalculoTalento.rentabilidadMensual?.toLocaleString() ||
          null,
        profitMargin:
          "%" +
            (
              dataCalculoTalento.rentabilidadTotal /
              (dataCalculoTalento.facturaEmitir *
                obtenerMeses(datosProyectos.duracion?.name))
            ).toFixed(2) || null,
        invoiceToIssue:
          "$" + dataCalculoTalento.facturaEmitir?.toLocaleString() || null,
        invoiceToIssueMonth:
          "$" + dataCalculoTalento.facturaEmitir?.toLocaleString() || null,
        invoiceToIssueTotal:
          "$" + (dataCalculoTalento.facturaEmitir * 12)?.toLocaleString() ||
          null,
        projectCosts:
          "$" + dataCalculoTalento.costoTotal?.toLocaleString() || null,
        subtotalCosts:
          "$" + dataCalculoTalento.subtotalCostos?.toLocaleString() || null,
        totalAdditionalCosts:
          "$" + dataCalculoTalento?.sumaTotalOtrosCostos?.toLocaleString() || 0,
        totalCosts:
          "$" +
            (
              dataCalculoTalento.costoTotal *
              obtenerMeses(datosProyectos.duracion?.name)
            )?.toLocaleString() || null,

        arsOverallProfitability:
          "$" +
            dataCalculoTalento.rentabilidadTotalOriginal?.toLocaleString() ||
          null,
        arsMonthlyProfit:
          "$" +
            dataCalculoTalento.rentabilidadMensualOriginal?.toLocaleString() ||
          null,
        arsProfitMargin:
          "%" +
            (
              dataCalculoTalento.rentabilidadTotal /
              (dataCalculoTalento.facturaEmitir *
                obtenerMeses(datosProyectos.duracion?.name))
            ).toFixed(2) ||
          null ||
          null,
        arsInvoiceToIssue:
          "$" + dataCalculoTalento.facturaEmitirOriginal?.toLocaleString() ||
          null,
        arsInvoiceToIssueMonth:
          "$" + dataCalculoTalento.facturaEmitirOriginal?.toLocaleString() ||
          null,
        arsInvoiceToIssueTotal:
          "$" +
            dataCalculoTalento.facturaEmitirTotalOriginal?.toLocaleString() ||
          null,
        arsProjectCosts:
          "$" + dataCalculoTalento.costoTotalOriginal?.toLocaleString() || null,
        arsSubtotalCosts:
          "$" + dataCalculoTalento.subtotalCostosOriginal?.toLocaleString() ||
          null,
        arsTotalAdditionalCosts: totalOtrosCostos?.suma
          ? "$" + totalOtrosCostos.suma.toLocaleString()
          : 0,
        arsTotalCosts:
          "$" +
            (
              dataCalculoTalento.costoTotalOriginal *
              obtenerMeses(datosProyectos.duracion?.name)
            )?.toLocaleString() || null,
      },
    },
    [getTeamDataPropertyName(datosProyectos.tipoProyecto)]: (() => {
      if (datosProyectos.tipoProyecto === "Contractor") {
        return talento.map((item) => ({
          base: item.base,
          role: item.rol,
          amount: item.cantidad,
          bandaSalarial: item.bandaSalarial,
          SB: "$" + item.sueldoBruto?.toLocaleString(),
          employmentType: item.tipoContratacion,
          costs:
            "$" + parseFloat(item.costoUnitario.toFixed(2))?.toLocaleString(),
          salesCoefficient: item.cfteVenta,
          subtotal: "$" + item.subTotal?.toLocaleString(),
          otherCosts: totalOtrosCostos?.suma
            ? "$" + totalOtrosCostos.suma.toLocaleString()
            : "$" + 0,
          benefitsPackage: item.paqueteBeneficios.name,
          vacationsDays: item.diasVacaciones.daysAmount,
        }));
      } else if (datosProyectos.tipoProyecto === "Tarifa") {
        return talento.map((item) => ({
          base: item.base,
          role: item.rol,
          amount: item.cantidad,
          profiling: item.perfilado,
          profilingPercentage: item.perfiladoPorcentaje,
          SB: "$" + item.sueldoBruto?.toLocaleString(),
          employmentType: item.tipoContratacion,
          costs:
            "$" + parseFloat(item.costoUnitario.toFixed(2))?.toLocaleString(),
          salesCoefficient: item.cfteVenta,
          subtotal: "$" + item.subTotal?.toLocaleString(),
          otherCosts: totalOtrosCostos?.suma
            ? "$" + totalOtrosCostos.suma.toLocaleString()
            : "$" + 0,
          benefitsPackage: item.paqueteBeneficios.name,
          vacationsDays: item.diasVacaciones.daysAmount,
        }));
      } else {
        return talento.map((item) => ({
          base: item.base,
          role: item.rol,
          amount: item.cantidad,
          bandaSalarial: item.bandaSalarial,
          SB: "$" + item.sueldoBruto?.toLocaleString(),
          employmentType: item.tipoContratacion,
          costs:
            "$" + parseFloat(item.costoUnitario.toFixed(2))?.toLocaleString(),
          saleHourlyCost:
            "$" + parseFloat(item.valorHoraCosto.toFixed(2)).toLocaleString(),
          amountHours: item.cantHoras,
          subtotal: "$" + item.subTotal?.toLocaleString(),
          salesCoefficient: item.cfteVenta,
          saleHourlyRate:
            "$" + parseFloat(item.horaVenta.toFixed(2)).toLocaleString(),
          salesTotal: "$" + item.ventaTotal.toLocaleString(),
          otherCosts: totalOtrosCostos?.suma
            ? "$" + totalOtrosCostos.suma.toLocaleString()
            : "$" + 0,
          benefitsPackage: item.paqueteBeneficios.name,
          vacationsDays: item.diasVacaciones.daysAmount,
        }));
      }
    })(),
    employeesAmount: teamData.amount,
    otherCosts: {
      projectDuration: datosProyectos.duracion.name,
      commissionsOnBilling: "5.5%",
      comisionesFacturacion: totalOtrosCostos?.comisionFacturacion || 0,
      otrosGastos: totalOtrosCostos?.otrosGastosPorc || 0,
      gastosGenerales: totalOtrosCostos?.gastosGenerales || 0,
    },
  };

  const bodyPdf = {
    number: 1234,
    date: fechaHora().fecha,
    [idCotizacion ? "idUser" : "user"]: idCotizacion
      ? dataCalculoTalento.idUser
      : username,
    client: datosProyectos.cliente?.name || datosProyectos.cliente,
    projectName: datosProyectos.nombreOportunidad,
    projectType: datosProyectos.tipoProyecto,
    lec: datosProyectos.tieneLEC === "si" ? true : false,
    duration: datosProyectos.duracion.name,
    grossIncome:
      datosProyectos.impuesto.name + " " + datosProyectos.impuesto.percent,
    paymentMethod: datosProyectos.formaPago.name,
    roles: talento.map((e) => ({
      role: e.rol,
      seniority: e.seniority,
      amount: e.cantidad,
      hours: e.cantHoras,
      value: parseFloat(e.costoUnitario.toFixed(2)).toLocaleString(),
      sueldoBruto:
        datosProyectos.moneda === "$"
          ? parseFloat(e.sueldoBruto.toFixed(2)).toLocaleString()
          : simboloMoneda +
            " " +
            parseFloat((e.sueldoBruto / price).toFixed(2)).toLocaleString(),
      cfteVenta: e.cfteVenta,
      totalVenta:
        datosProyectos.moneda === "$"
          ? "$" + parseFloat(e.ventaTotal.toFixed(2)).toLocaleString()
          : simboloMoneda +
            " " +
            parseFloat((e.ventaTotal / price).toFixed(2)).toLocaleString(),
      subVenta:
        datosProyectos.moneda === "$"
          ? "$" + parseFloat(e.subTotal.toFixed(2)).toLocaleString()
          : simboloMoneda +
            " " +
            parseFloat((e.subTotal / price).toFixed(2)).toLocaleString(),
    })),
    totalAmount:
      datosProyectos.tipoProyecto === "Contractor" ||
      datosProyectos.tipoProyecto === "Tarifa"
        ? datosProyectos.moneda === "$"
          ? parseFloat(
              dataCalculoTalento.facturaEmitirTotal.toFixed(2)
            ).toLocaleString()
          : parseFloat(
              dataCalculoTalento.facturaEmitirTotal.toFixed(2)
            ).toLocaleString() +
            "\n" +
            simboloMoneda +
            " " +
            parseFloat(
              (dataCalculoTalento.facturaEmitirTotal / price).toFixed(2)
            ).toLocaleString()
        : datosProyectos.moneda === "$"
        ? parseFloat(
            dataCalculoTalento.facturaEmitirOriginal.toFixed(2)
          ).toLocaleString()
        : parseFloat(
            dataCalculoTalento.facturaEmitirOriginal.toFixed(2)
          ).toLocaleString() +
          "\n" +
          simboloMoneda +
          " " +
          parseFloat(
            (dataCalculoTalento.facturaEmitirOriginal / price).toFixed(2)
          ).toLocaleString(),
    version: version,
    totalHours: datosProyectos.horas || 0,
    currentType: datosProyectos.moneda,
    monthlyAmount:
      datosProyectos.moneda === "$"
        ? parseFloat(
            dataCalculoTalento.facturaEmitirOriginal.toFixed(2)
          ).toLocaleString()
        : parseFloat(
            dataCalculoTalento.facturaEmitirOriginal.toFixed(2)
          ).toLocaleString() +
          "\n" +
          simboloMoneda +
          " " +
          parseFloat(
            (dataCalculoTalento.facturaEmitirOriginal / price).toFixed(2)
          ).toLocaleString(),
  };

  const handleClickExcel = async () => {
    const blob = await getExcelCotizacion(cotizacion);
    if (blob) {
      const urlArchivo = URL.createObjectURL(blob);
      const linkDescarga = document.createElement("a");
      linkDescarga.href = urlArchivo;
      linkDescarga.download =
        "Resumen cotizacion" +
        " _ " +
        fechaHora().fecha +
        " _ " +
        fechaHora().hora;
      linkDescarga.click();
      URL.revokeObjectURL(urlArchivo);
      //  strParam = "";
      //xlsDialogClose();
    }
  };

  const handleClickPdf = async () => {
    const blob = await getPdfCotizacion(bodyPdf);
    if (blob) {
      const urlArchivo = URL.createObjectURL(blob);
      const linkDescarga = document.createElement("a");
      linkDescarga.href = urlArchivo;
      linkDescarga.download =
        "Resumen cotizacion" +
        " _ " +
        fechaHora().fecha +
        " _ " +
        fechaHora().hora;
      linkDescarga.click();
      URL.revokeObjectURL(urlArchivo);
      //  strParam = "";
      //xlsDialogClose();
    }
  };

  const handleGuardar = () => {
    const talentos = dataTablaTalento.map((talento) => {
      return {
        base: talento.base,
        role: talento.rol,
        seniority: talento.seniority,
        skill: talento.skill,
        amount: talento.cantidad,
        bandaSalarial: talento.bandaSalarial,
        perfilado: talento.perfilado,
        perfiladoPorcentaje: talento.perfiladoPorcentaje,
        SB: talento.sueldoBruto,
        employmentType: talento.tipoContratacion,
        costs: talento.costoUnitario,
        valorHoraCosto: talento.valorHoraCosto,
        cantHoras: talento.cantHoras,
        subtotalCosto: talento.subtotalCosto,
        cfteVenta: talento.cfteVenta,
        subtotalVenta: talento.subTotal,
        valorHoraVenta: talento.horaVenta,
        ventaTotal: talento.ventaTotal,
        benefitsPackage: talento.paqueteBeneficios.name,
        vacationsDays: talento.diasVacaciones.daysAmount,
        variablesCosts: talento.costosVariables,
        fijosCosts: talento.costosFijos,
      };
    });
    const datosEnviar = {
      version: version,
      projectData: {
        client: cotizacion.projectData.firstTable.client,
        isClient: datosProyectos.esCliente === "si" ? true : false,
        projectName: cotizacion.projectData.firstTable.projectName,
        projectType: cotizacion.projectData.firstTable.projectType,
        duration: cotizacion.projectData.firstTable.duration,
        hours: cotizacion.projectData.firstTable.hours,
        paymentTerms: cotizacion.projectData.firstTable.paymentTerms,
        grossIncomeTax: cotizacion.projectData.firstTable.grossIncomeTax,
        LEC: cotizacion.projectData.firstTable.LEC,
      },
      talentos: talentos,
      calculateTable: {
        modal: {
          comisions: totalOtrosCostos.comisionFacturacion,
          estructuraServices: totalOtrosCostos.estructuraServicios,
          overallGastos: totalOtrosCostos.gastosGenerales,
          otherGastosPercentage: totalOtrosCostos.otrosGastosPorc,
          otherGastos: totalOtrosCostos.otrosGastosPesos,
          costoFinanciero: totalOtrosCostos.costoFinanciero?.toString(),
          impDebYCred: totalOtrosCostos.impuestoDebitoCredito?.toString(),
          impIngresosBrutos:
            totalOtrosCostos.impuestoIngresosBrutos?.toString(),
        },
        table: {
          overallProfitability: dataCalculoTalento.rentabilidadTotal,
          monthlyProfit: dataCalculoTalento.rentabilidadMensual,
          oProfitMargin: dataCalculoTalento.porcentajeTotal?.toString(),
          mProfitMargin: dataCalculoTalento.porcentajeMensual?.toString(),
          subtotalCosts: dataCalculoTalento.subtotalCostos,
          additionalCosts: dataCalculoTalento.sumaTotalOtrosCostos,
          totalCosts: dataCalculoTalento.costoTotal,
          invoiceToIssue: dataCalculoTalento.facturaEmitir,
          totalInvoice: dataCalculoTalento.facturaEmitirTotal ,
        },
      },
      employeesAmount: cotizacion.employeesAmount,
      currency:
        datosProyectos.moneda === "$"
          ? "Peso argentino"
          : datosProyectos.moneda,
    };
    if (idCotizacion) {
      putCotizaciones(datosEnviar);
    } else {
      postCotizaciones(datosEnviar);
    }
  };

  const items = [
    {
      label: "EXCEL",
      // icon: 'pi pi-refresh',
      command: () => {
        handleClickExcel();
      },
    },
    {
      label: "PDF",
      // icon: 'pi pi-times',
      command: () => {
        handleClickPdf();
      },
    },
  ];

  return (
    <div className="custom-container">
      <Card className="custom-summary-card">
        <h2 style={{ fontSize: "20px" }}> Ya casi estamos! 🚀</h2>
        <p style={{ marginBottom: "15px", fontSize: "16px" }}>
          Revisá los detalles de la cotización antes de descargar.
        </p>
        <table className="custom-details">
          <tbody>
            <tr>
              <td className="td-title">
                <strong>Versión</strong>
              </td>
              <td className="td-subtitle">{version}</td>
              <td className="td-title">
                <strong>Cantidad de talentos:</strong>
              </td>
              <td className="td-subtitle"> {cotizacion.employeesAmount}</td>
            </tr>
            <tr>
              <td className="td-title">
                <strong>Cliente:</strong>
              </td>
              <td className="td-subtitle">
                {cotizacion.projectData.firstTable.client}
              </td>
              <td className="td-title">
                <strong>Rentabilidad Mensual:</strong>
              </td>
              <td className="td-subtitle">
                {dataCalculoTalento.simboloMoneda === "$"
                  ? "$" +
                    dataCalculoTalento.rentabilidadMensual?.toLocaleString()
                  : "$" +
                    dataCalculoTalento.rentabilidadMensualOriginal?.toLocaleString() +
                    " " +
                    " / " +
                    dataCalculoTalento.rentabilidadMensual?.toLocaleString() +
                    datosProyectos.moneda}
              </td>
            </tr>
            <tr>
              <td className="td-title">
                <strong>Nombre de la oportunidad:</strong>
              </td>
              <td className="td-subtitle">
                {cotizacion.projectData.firstTable.projectName}
              </td>
              <td className="td-title">
                <strong>Porcentaje de rentabilidad (Mensual):</strong>
              </td>
              <td className="td-subtitle">
                {Number(dataCalculoTalento?.porcentajeMensual)}%
              </td>
            </tr>
            <tr>
              <td className="td-title">
                <strong>Tipo de proyecto:</strong>
              </td>
              <td className="td-subtitle">
                {cotizacion.projectData.firstTable.projectType}
              </td>
              <td className="td-title">
                <strong>Rentabilidad total:</strong>
              </td>
              <td className="td-subtitle">
                {dataCalculoTalento.simboloMoneda === "$"
                  ? "$" + dataCalculoTalento.rentabilidadTotal?.toLocaleString()
                  : "$" +
                    dataCalculoTalento.rentabilidadTotalOriginal?.toLocaleString() +
                    " / " +
                    dataCalculoTalento.rentabilidadTotal?.toLocaleString() +
                    datosProyectos.moneda}
              </td>
            </tr>
            <tr>
              <td className="td-title">
                <strong>Duración:</strong>
              </td>
              <td className="td-subtitle">
                {cotizacion.projectData.firstTable.duration}
              </td>
              <td className="td-title">
                <strong>Porcentaje de rentabilidad:</strong>
              </td>
              <td className="td-subtitle">
                {Number(dataCalculoTalento?.porcentajeTotal)}%
              </td>
            </tr>
            <tr>
              <td className="td-title">
                <strong>Forma de pago:</strong>
              </td>
              <td className="td-subtitle">
                {cotizacion.projectData.firstTable.paymentTerms}
              </td>
              <td className="td-title">
                <strong>Subtotal Costos:</strong>
              </td>
              <td className="td-subtitle">
                {dataCalculoTalento.simboloMoneda === "$"
                  ? "$" + dataCalculoTalento.subtotalCostos?.toLocaleString()
                  : "$" +
                    dataCalculoTalento.subtotalCostosOriginal?.toLocaleString() +
                    " / " +
                    dataCalculoTalento.subtotalCostos?.toLocaleString() +
                    dataCalculoTalento.simboloMoneda}
              </td>
            </tr>
            <tr>
              <td className="td-title">
                <strong>Imp. a los Ingresos Brutos:</strong>
              </td>
              <td className="td-subtitle">
                {" "}
                {cotizacion.projectData.firstTable.grossIncomeTax}
              </td>
              <td className="td-title">
                <strong>Total otros costos:</strong>
              </td>
              <td className="td-subtitle">
                {
                  dataCalculoTalento.simboloMoneda === "$"
                    ? "$" +
                      (totalOtrosCostos.suma
                        ? totalOtrosCostos.suma.toLocaleString()
                        : 0)
                    : "$" +
                      (totalOtrosCostos.suma
                        ? totalOtrosCostos.suma.toLocaleString()
                        : 0) +
                      " / " +
                      dataCalculoTalento.sumaTotalOtrosCostos?.toLocaleString() +
                      dataCalculoTalento.simboloMoneda /**/
                }
              </td>
            </tr>
            <tr>
              <td className="td-title">
                <strong>LEC:</strong>
              </td>
              <td className="td-subtitle">
                {" "}
                {cotizacion.projectData.firstTable.LEC === true ? "Si" : "No"}
              </td>
              <td className="td-title">
                <strong> Costo Total :</strong>
              </td>
              <td className="td-subtitle">
                {dataCalculoTalento.simboloMoneda === "$"
                  ? "$" +
                    (
                      dataCalculoTalento.costoTotalOriginal *
                      obtenerMeses(datosProyectos.duracion?.name)
                    )?.toLocaleString()
                  : "$" +
                    (
                      dataCalculoTalento.costoTotalOriginal *
                      obtenerMeses(datosProyectos.duracion?.name)
                    )?.toLocaleString() +
                    " / " +
                    dataCalculoTalento.costoTotal?.toLocaleString() +
                    dataCalculoTalento.simboloMoneda}
              </td>
            </tr>
            <tr>
              <td className="td-title">
                <strong>Tipo de moneda:</strong>
              </td>
              <td className="td-subtitle">
                {datosProyectos.moneda === "$"
                  ? "Pesos"
                  : datosProyectos.moneda}
              </td>
              <td className="td-title">
                <strong>
                  {selectedTipoProyecto.name === "Contractor" ||
                  selectedTipoProyecto.name === "Tarifa" ||
                  selectedTipoProyecto.name === "Servicios"
                    ? "Factura a emitir (mensual)"
                    : "Factura a emitir"}
                </strong>
              </td>
              <td className="td-subtitle">
                {dataCalculoTalento.simboloMoneda === "$"
                  ? "$" +
                    dataCalculoTalento.facturaEmitirOriginal?.toLocaleString()
                  : "$" +
                    dataCalculoTalento.facturaEmitirOriginal?.toLocaleString() +
                    " / " +
                    dataCalculoTalento.facturaEmitir?.toLocaleString() +
                    dataCalculoTalento.simboloMoneda}
              </td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              {(selectedTipoProyecto.name === "Contractor" ||
                selectedTipoProyecto.name === "Tarifa" ||
                selectedTipoProyecto.name === "Servicios") && (
                <>
                  <td className="td-title">
                    <strong>Factura total:</strong>
                  </td>
                  <td className="td-subtitle">
                    {dataCalculoTalento.simboloMoneda === "$"
                      ? "$" +
                        dataCalculoTalento.facturaEmitirTotalOriginal?.toLocaleString()
                      : "$" +
                        dataCalculoTalento.facturaEmitirTotalOriginal?.toLocaleString() +
                        " / " +
                        dataCalculoTalento.facturaEmitirTotal?.toLocaleString() +
                        dataCalculoTalento.simboloMoneda}
                  </td>
                </>
              )}
            </tr>
          </tbody>
        </table>
      </Card>
      <div className="custom-footer">
        <Button
          label="VOLVER AL COTIZADOR"
          onClick={() => {
            navigate("/cotizador/talentos");
          }}
          style={{ color: "#535353", backgroundColor: "transparent" }}
        />
        <Button
          label={idCotizacion ? "Actualizar cotización" : "GUARDAR COTIZACION"}
          className="p-button-primary"
          onClick={handleGuardar}
        />

        <SplitButton
          label="DESCARGAR COTIZACION"
          model={items}
          className="mr-2 mb-2"
        ></SplitButton>
        {/* <Button
          label="DESCARGAR EXCEL"
          className="p-button-primary"
          onClick={handleClickExcel}
        />
        <Button
          label="DESCARGAR PDF"
          className="p-button-primary"
          onClick={handleClickPdf}
        /> */}
      </div>
    </div>
  );
};

export default DetalleDeCotizacion;
