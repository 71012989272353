import React, { useState, useEffect, useRef, useContext } from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { classNames } from "primereact/utils";
import { InputText } from "primereact/inputtext";
import { InputNumber } from "primereact/inputnumber";
import { Toast } from "primereact/toast";
import { useFormik } from "formik";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import FieldCecos from "../../components/FieldCecos";
import { NovedadesContext } from "../../contexts/NovedadesContext";
import "./usuarioExterno.css";

const FormUsuarioExterno = ({
  setDisplayModal,
  displayModal,
  position = "right",
  datosRegistro = null,
  verSoloLectura = false,
}) => {
  const [arrayCecos, setArrayCecos] = useState([]);
  const [porcentajeTotal, setPorcentajeTotal] = useState();
  const { insertRecurso, updateRecurso } = useContext(NovedadesContext);
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const toast = useRef(null);

  useEffect(() => {
    let transformer = datosRegistro?.Rcc?.map((data, index) => {
      return {
        id: index,
        codigo: data.CcNum,
        cliente: data.CcNombre,
        porcentaje: data.CcPorc,
      };
    });
    setArrayCecos(transformer || []);
  }, [displayModal]);

  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <small className="p-error">{formik.errors[name]}</small>
      )
    );
  };

  const calcularPorcentaje = () => {
    let total = null;
    for (let i = 0; i < arrayCecos?.length; i++) {
      total += arrayCecos[i].porcentaje;
    }
    setPorcentajeTotal(total);
  };

  const errorPorcentaje = (valueCecosPorcentaje) => {
    if (valueCecosPorcentaje > 100) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "La suma de porcentajes de proyectos debe ser igual a 100",
        life: 5000,
      });
    }
  };

  const onHide = (name) => {
    setDisplayModal(false);
    handleClickOnHide(name);
    if (name === "displayModal") {
      formik.resetForm();
      setArrayCecos([]);
    }
  };

  const handleClickOnHide = (name) => {
    if (name === "displayModal") {
      return;
    }
    confirmDialog({
      draggable: false,
      message: "¿Desea cerrar el formulario?",
      header: "Confirmación",
      icon: "pi pi-exclamation-triangle",
      acceptLabel: "Aceptar",
      rejectLabel: "Cancelar",
      accept: () => onHide("displayModal"),
      reject: () => setDisplayModal(true),
      onHide: (e) => {
        if (e === "reject") {
          setDisplayModal(true);
        } else {
          onHide("displayModal");
        }
      },
    });
  };

  const soloNumerosValue = (e) => {
    const value = e.target.value;
    if (/^[0-9]*$/.test(value) && Number(value) >= 0) {
      formik.setFieldValue("gerente", value);
    } else if (value === '') {
      formik.setFieldValue("gerente", '');
    }
  };
  
  const soloNumerosKeyDown = (e) => {
    if (!/[0-9]/.test(e.key) && e.key !== 'Backspace') {
      e.preventDefault();
    }
  };

  const soloLetrasValue = (valor) => {
    const regex = /^[a-zA-ZÀ-ÿ\u00f1\u00d1\s]*$/;
    return regex.test(valor);
  };
  
  const validarYActualizar = (formik, field) => (event) => {
    const { value } = event.target;
    if (soloLetrasValue(value)) {
      formik.setFieldValue(field, value);
    }
  };

  const formik = useFormik({
    initialValues: {
      legajo: datosRegistro?.Legajo || null,
      apellido: datosRegistro?.Apellido || "",
      nombre: datosRegistro?.Nombre || "",
      gerente: datosRegistro?.Gerente || null,
      email: datosRegistro?.Mail || "",
    },
    validate: (data) => {
      let errors = {};
      if (!data.legajo) {
        errors.legajo = "Campo requerido.";
      }
      if (data.legajo < 10000) {
        errors.legajo = "Legajo menor a 10000.";
      }
      if (!data.apellido) {
        errors.apellido = "Campo requerido.";
      }
      if (!data.nombre) {
        errors.nombre = "Campo requerido.";
      }
      if (!data.gerente) {
        errors.gerente = "Campo requerido.";
      }
      if (data.email && !emailRegex.test(data.email)) {
        errors.email = "Debe ingresar un email válido.";
      }
      return errors;
    },
    enableReinitialize: true,
    onSubmit: () => {
      formik.resetForm();
      onHide("displayModal");
      let distArray = [];

      arrayCecos.forEach((e) => {
        distArray.push({
          CcPorc: parseFloat(e.porcentaje),
          //          CcNombre: e.cliente,
          CcNum: e.codigo.toString(),
        });
      });

      datosRegistro === null
        ? insertRecurso({
            legajo: formik.values.legajo,
            apellido: formik.values.apellido,
            nombre: formik.values.nombre,
            gerente: formik.values.gerente,
            rcc: distArray,
            mail: formik.values.email,
          })
        : updateRecurso({
            legajo: formik.values.legajo,
            apellido: formik.values.apellido,
            nombre: formik.values.nombre,
            gerente: formik.values.gerente,
            rcc: distArray,
            mail: formik.values.email,
          });
    },
  });

  return (
    <Dialog
      draggable={false}
      header={
        datosRegistro
          ? (verSoloLectura ? "Ver " : "Edición ") +
            "usuario externo #" +
            datosRegistro.Legajo
          : "Nuevo usuario externo"
      }
      blockScroll={true}
      visible={displayModal}
      position={position}
      modal
      className="dialog-freelance"
      style={{
        width: "50vw",
        height: "100vh",
        maxHeight: "100vh",
        margin: "0",
        fontFamily: "var(--main-font)",
      }}
      onHide={() => {
        verSoloLectura ? onHide("displayModal") : onHide("onHide");
      }}
    >
      <ConfirmDialog />
      <Toast ref={toast} />
      <div className="flex justify-content-center form-nuevo-cc">
        <form onSubmit={formik.handleSubmit} className="p-fluid">
          <div className="row-container">
            <div className="input-container medium">
              <span className="p-float-label">
                <InputNumber
                  id="legajo"
                  value={formik.values.legajo}
                  autoComplete="off"
                  onValueChange={formik.handleChange}
                  readOnly={verSoloLectura}
                  className={classNames({
                    "p-invalid": isFormFieldValid("legajo"),
                  })}
                  disabled={verSoloLectura}
                />
                <label
                  htmlFor="legajo"
                  className={classNames({
                    "p-error": isFormFieldValid("legajo"),
                  })}
                >
                  Legajo*
                </label>
              </span>
              {getFormErrorMessage("legajo")}
            </div>
            <div className="input-container medium">
              <span className="p-float-label">
                <InputText
                  id="apellido"
                  value={formik.values.apellido}
                  autoComplete="off"
                  onChange={validarYActualizar(formik, "apellido")}
                  readOnly={verSoloLectura}
                  className={classNames({
                    "p-invalid": isFormFieldValid("apellido"),
                  })}
                  disabled={verSoloLectura}
                />
                <label
                  htmlFor="apellido"
                  className={classNames({
                    "p-error": isFormFieldValid("apellido"),
                  })}
                >
                  Apellido*
                </label>
              </span>
              {getFormErrorMessage("apellido")}
            </div>
            <div className="input-container medium">
              <span className="p-float-label">
                <InputText
                  id="nombre"
                  value={formik.values.nombre}
                  autoComplete="off"
                  onChange={validarYActualizar(formik, "nombre")}
                  readOnly={verSoloLectura}
                  className={classNames({
                    "p-invalid": isFormFieldValid("nombre"),
                  })}
                  disabled={verSoloLectura}
                />
                <label
                  htmlFor="nombre"
                  className={classNames({
                    "p-error": isFormFieldValid("nombre"),
                  })}
                >
                  Nombre*
                </label>
              </span>
              {getFormErrorMessage("nombre")}
            </div>
          </div>
          <div className="row-container">
            <div className="input-container medium">
              <span className="p-float-label">
                <InputText
                  id="email"
                  value={formik.values.email}
                  autoComplete="off"
                  onChange={formik.handleChange}
                  // readOnly={verSoloLectura}
                  disabled={datosRegistro?.Mail}
                  className={classNames({
                    "p-invalid": isFormFieldValid("email"),
                  })}
                />
                <label
                  htmlFor="email"
                  className={classNames({
                    "p-error": isFormFieldValid("email"),
                  })}
                >
                  Email
                </label>
                {getFormErrorMessage("email")}
              </span>
            </div>
          </div>
          <div className="row-container">
            {" "}
            <div className="input-container medium">
              <span className="p-float-label">
                <InputText
                  id="gerente"
                  value={formik.values.gerente}
                  autoComplete="off"
                  onChange={(e) => soloNumerosValue (e)}
                  onKeyDown={soloNumerosKeyDown}
                  readOnly={verSoloLectura}
                  className={classNames({
                    "p-invalid": isFormFieldValid("gerente"),
                  })}
                  disabled={verSoloLectura}
                />
                <label
                  htmlFor="gerente"
                  className={classNames({
                    "p-error": isFormFieldValid("gerente"),
                  })}
                >
                  Gerente*
                </label>
              </span>
              {getFormErrorMessage("gerente")}
            </div>
          </div>
          <FieldCecos
            calcularPorcentaje={calcularPorcentaje}
            arrayCecos={arrayCecos}
            setArrayCecos={setArrayCecos}
            errorPorcentaje={errorPorcentaje}
            verSoloLectura={verSoloLectura}
            novedadTipo={datosRegistro !== null && "freelance"}
          />

          <div className="div_boton-fixed">
            <div className="final-submit">
              <Button type="submit" label="Guardar" disabled={verSoloLectura} />
            </div>
          </div>
        </form>
      </div>
    </Dialog>
  );
};

export default FormUsuarioExterno;
