export const formatDateToISO = (date) => {
    if (typeof date === "string") {
      const [day, month, year] = date.split("/");
      return `${year}-${month}-${day}`;
    } else if (date instanceof Date) {
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const day = date.getDate().toString().padStart(2, "0");
      return `${year}-${month}-${day}`;
    }
  };

export const formatDateToReverse = (date) => {
    if (date instanceof Date) {
      const day = date.getDate().toString().padStart(2, "0");
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const year = date.getFullYear();
      return `${day}/${month}/${year}`;
    } else if (typeof date === "string") {
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    }
  };

export const formatDateToReverseMesDiaAño = (date) => {
    if (typeof date === "string") {
      const [day, month, year] = date.split("/");
      return `${month}/${day}/${year}`;
    } else {
      return null;
    }
  };