import React, { useContext, useEffect, useState } from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Calendar } from "primereact/calendar";
import { RadioButton } from "primereact/radiobutton";
import { NovedadesContext } from "../../contexts/NovedadesContext";
import { fechaHora } from "../../helpers";
import { formateaFechaMMAAAA } from "../../helpers/formateaFechaMMAAAA";
import { SELECT_EXPORT_TYPES } from "./types";
import "./dialogExportExcel.css";

const DialogExportExcel = ({
  usuario,
  xlsTipo,
  opciones = false,
  xlsDialogVis,
  setXlsDialogVis,
}) => {
  const { getExcel, loadingExcel } = useContext(NovedadesContext);
  const [xlsFechaDesde, setXlsFechaDesde] = useState(null);
  const [xlsFechaHasta, setXlsFechaHasta] = useState(null);
  const [xlsPeriodo, setXlsPeriodo] = useState(null);
  const [tipoSelecExport, setTipoSelecExport] = useState(
    SELECT_EXPORT_TYPES.periodo
  );

  const handleClick = async () => {
    let strParam = "";
    if (tipoSelecExport === SELECT_EXPORT_TYPES.periodo) {
      if (xlsPeriodo) {
        strParam = `/?periodo=${formateaFechaMMAAAA(xlsPeriodo)}`;
      }
    }
    if (tipoSelecExport === SELECT_EXPORT_TYPES.rango) {
      if (xlsFechaDesde && xlsFechaHasta) {
        strParam = `/?fechaDesde=${fechaHora(xlsFechaDesde).fecha}&fechaHasta=${
          fechaHora(xlsFechaHasta).fecha
        }`;
      }
    }

    const blob = await getExcel(xlsTipo, strParam);
    if (blob) {
      const urlArchivo = URL.createObjectURL(blob);
      const linkDescarga = document.createElement("a");
      linkDescarga.href = urlArchivo;
      linkDescarga.download =
        "Informe novedades" +
        " _ " +
        fechaHora().fecha +
        " _ " +
        fechaHora().hora;
      linkDescarga.click();
      URL.revokeObjectURL(urlArchivo);
      strParam = "";
      xlsDialogClose();
    }
  };

  const xlsDialogClose = () => {
    setTipoSelecExport(SELECT_EXPORT_TYPES.periodo);
    setXlsPeriodo(null);
    setXlsFechaDesde(null);
    setXlsFechaHasta(null);
    setXlsDialogVis(false);
  };

  return (
    <>
      {(usuario.Rol === "admin" ||
        usuario.Rol === "po" ||
        usuario.Rol === "poFull") && (
        <Dialog
          draggable={false}
          resizable={false}
          style={{ minWidth: "450px", minHeight: "280px" }}
          header="Informe exportable a Excel"
          visible={xlsDialogVis}
          onHide={() => xlsDialogClose()}
        >
          <div className="flex flex-wrap gap-3">
            <div className="flex align-items-center">
              <RadioButton
                inputId="opcion1"
                name="opcion1"
                disabled={!opciones}
                style={{ marginTop: "10px", marginRight: "5px" }}
                value={SELECT_EXPORT_TYPES.periodo}
                onChange={(e) => {
                  setTipoSelecExport(e.value);
                  setXlsFechaDesde(null);
                  setXlsFechaHasta(null);
                }}
                checked={tipoSelecExport === SELECT_EXPORT_TYPES.periodo}
              />
              <label htmlFor="opcion1" className="ml-2">
                Período
              </label>
            </div>
          </div>
          <div className="flex align-items-center">
            <RadioButton
              inputId="opcion2"
              name="opcion2"
              disabled={!opciones}
              style={{ marginTop: "10px", marginRight: "5px" }}
              value={SELECT_EXPORT_TYPES.rango}
              onChange={(e) => {
                setTipoSelecExport(e.value);
                setXlsPeriodo(null);
              }}
              checked={tipoSelecExport === SELECT_EXPORT_TYPES.rango}
            />
            <label htmlFor="opcion2" className="ml-2">
              Rango
            </label>
          </div>
          <div className="container-fecha">
            {tipoSelecExport === SELECT_EXPORT_TYPES.periodo && (
              <div className="input-container-fecha">
                <span className="titulo-calendar">
                  Seleccionar período MM/AAAA
                </span>
                <Calendar
                  view="month"
                  locale="es"
                  dateFormat="mm/yy"
                  showIcon
                  style={{ height: "30px", marginTop: "6px" }}
                  value={xlsPeriodo}
                  onChange={(e) => setXlsPeriodo(e.value)}
                  disabled={!opciones}
                />
              </div>
            )}
            {tipoSelecExport === SELECT_EXPORT_TYPES.rango && (
              <>
                <div className="input-container-fecha">
                  <span className="titulo-calendar">Fecha desde</span>
                  <Calendar
                    locale="es"
                    dateFormat="dd/mm/yy"
                    showIcon
                    style={{ height: "30px", marginTop: "6px" }}
                    value={xlsFechaDesde}
                    onChange={(e) => setXlsFechaDesde(e.value)}
                    disabled={!opciones}
                  />
                </div>
                <div className="input-container-fecha">
                  <span className="titulo-calendar">Fecha Hasta</span>
                  <Calendar
                    locale="es"
                    dateFormat="dd/mm/yy"
                    showIcon
                    style={{ height: "30px", marginTop: "6px" }}
                    value={xlsFechaHasta}
                    onChange={(e) => setXlsFechaHasta(e.value)}
                    disabled={!opciones}
                  />
                </div>
              </>
            )}
          </div>
          <div className="boton-informe">
            <Button
              disabled={
                (loadingExcel ||
                  !(xlsPeriodo || (xlsFechaDesde && xlsFechaHasta))) &&
                opciones
              }
              className="p-button1"
              label="Descargar Excel"
              onClick={handleClick}
              style={{ padding: "3px", width: "150px", maxHeight: "50px" }}
              icon={
                loadingExcel ? (
                  <i
                    className="pi pi-spin pi-spinner"
                    style={{ fontSize: "1rem" }}
                  ></i>
                ) : (
                  "pi pi-file-excel"
                )
              }
            />
          </div>
        </Dialog>
      )}
    </>
  );
};

export { DialogExportExcel };
