import {
  useState,
  useContext,
  useEffect,
  useRef,
  useImperativeHandle,
  forwardRef,
} from "react";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { Dropdown } from "primereact/dropdown";
import { Tooltip } from "primereact/tooltip";
import { Button } from "primereact/button";
import { AuthContext } from "../contexts/AuthContext";
import { NovedadesContext } from "../contexts/NovedadesContext";
import ModalAceptada from "./ModalAceptada";
import ModalRechazada from "./ModalRechazada";
import {
  IDBodyTemplate,
  capitalizarPrimeraLetra,
  fechaHoraPopup,
} from "../helpers";
import PopUpResumen from "./PopUpResumen";
import { InputMask } from "primereact/inputmask";
import "../styles/tableRecursos.css";
import { formatNumber } from "../helpers/index";
import * as XLSX from "xlsx";

const TableRecursos = forwardRef(
  ({ bandeja, allChecked, novedadesGrupoState }, ref) => {
    const {
      loading,
      getNovedades,
      cambioNovedad,
      getNovedadesPendientes,
      getNovedadesAYR,
    } = useContext(NovedadesContext);
    const { usuario, username } = useContext(AuthContext);
    const [estados] = useState(["Pendiente", "Aceptada", "Rechazada"]);
    const [novedades, setNovedades] = useState([]);
    const [novedadesFiltradas, setNovedadesFiltradas] = useState([]);
    const [visibleModalAceptar, setVisibleModalAceptar] = useState(false);
    const [visibleModalRechazar, setVisibleModalRechazar] = useState(false);
    const [novedadSeleccionada, setNovedadSeleccionada] = useState({});
    const [resumenVisible, setResumenVisible] = useState(false);
    const [rowDataSelection, setRowDataSelection] = useState({});
    const [tipoDeNovedad, setTipoDeNovedad] = useState();
    const dt = useRef(null);
    const [filteredData, setFilteredData] = useState([]);
    // const index = usuario.Email.indexOf("@");
    // const user = usuario.Email.slice(0, index);

    const [filters, setFilters] = useState({
      IdSecuencial: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
      Fecha: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
      Descripcion: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
      Periodo: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
      Adjuntos: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
      Resumen: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
      Usuario: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
      Estado: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
      },
    });

    useEffect(() => {
      const userRol = () => {
        const roles = {
          serviciosSop: "servicios",
          comercialSop: "comercial",
        };
        const rol = roles[usuario.Rol] || usuario.Rol;
        return usuario.Rol === "board" ? "" : `rol=${rol}`;
      };
      const handleGetNovedades = async () => {
        const data = !bandeja
          ? await getNovedades(
              "tipo=RH|PB",
              novedadesGrupoState ? userRol() : "usuario=" + username
            )
          : await getNovedadesPendientes();
        const dataAceptada = await getNovedadesAYR();

        const dataRol = data.filter(
          (e) => e.Tipo === "RH" || e.Tipo === "NP" || e.Tipo === "PB"
        );

        const dataRolAceptada = dataAceptada.filter(
          (e) => e.Tipo === "RH" || e.Tipo === "NP" || e.Tipo === "PB"
        );

        setNovedades(!bandeja ? data : dataRol.concat(dataRolAceptada));
        setNovedadesFiltradas(
          !bandeja ? data.filter((e) => e.Estado === "pendiente") : dataRol
        );
        setFilteredData(data.filter((e) => e.Estado === "pendiente"));
      };
      handleGetNovedades();

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cambioNovedad, novedadesGrupoState]);

    useEffect(() => {
      setFilteredData(dt.current.props.value);
    }, [dt]);

    useEffect(() => {
      setFilteredData(allChecked ? novedades : novedadesFiltradas);
    }, [allChecked]);

    const handleResumenClick = (rowData) => {
      setTipoDeNovedad("Recursos");
      setResumenVisible(true);
      setRowDataSelection(rowData);
    };

    const limitarCaracteres = (value, maxLength) => {
      if (value.length > maxLength) {
        return value.substring(0, maxLength) + "...";
      }
      return value;
    };

    const resumenBodyTemplate = (rowData) => {
      return (
        <div
          className="campoResumen"
          onClick={() => handleResumenClick(rowData)}
        >
          {novedadesGrupoState && (
            <>
              <strong>Usuario: </strong> {rowData.Usuario} <br />
            </>
          )}
          <strong>Descripción: </strong>
          {rowData.Descripcion}
          <br />
          {rowData.ImporteTotal !== 0 && (
            <>
              <strong>Importe Total: </strong>${" "}
              {formatNumber(rowData.ImporteTotal)}
              <br />
            </>
          )}
          {rowData.FechaDesde !== "" &&
            (rowData.FechaHasta !== "" ? (
              <>
                <strong>Fecha desde: </strong>
                {rowData.FechaDesde}
                <br />
                <strong>Fecha hasta: </strong>
                {rowData.FechaHasta}
                <br />
              </>
            ) : (
              <>
                <strong>Fecha: </strong>
                {rowData.FechaDesde}
                <br />
              </>
            ))}
          {rowData.Cantidad && rowData.Cantidad !== "0" && (
            <>
              <strong>Cantidad de Dias: </strong>
              {rowData.Cantidad}
              <br />
            </>
          )}
          {rowData.Recursos?.length > 0 && (
            <>
              <strong>Recursos: </strong>
              {rowData.Recursos?.map((itemB, index) => {
                if (!rowData.Descripcion.includes("Actualizar sueldo básico")) {
                  if (index >= 0 && index <= 1) {
                    return (
                      <div key={index}>
                        {itemB.Recurso}
                        <br />
                        {itemB.Periodo && (
                          <>
                            <b>Periodo: </b>
                            {itemB.Periodo}
                            <br />
                          </>
                        )}
                        {itemB.Importe !== 0 && (
                          <>
                            <b>Importe: </b>${formatNumber(itemB.Importe)}
                          </>
                        )}
                      </div>
                    );
                  } else if (index === 2) {
                    return (
                      <div key={index}>
                        <b>
                          ...<i>Click para ver todos los recursos</i>
                        </b>
                      </div>
                    );
                  }
                  return (
                    <div key={index}>
                      {itemB.Recurso}
                      <br />
                    </div>
                  );
                }
                if (index >= 0 && index <= 1) {
                  return (
                    <div key={index}>
                      {itemB.Recurso} <br /> <i>SB Actual:</i> $
                      {formatNumber(itemB.SbActual)} - <i>Nuevo Básico:</i> $
                      {formatNumber(itemB.Importe)} - <i>Período:</i>{" "}
                      {itemB.Periodo}
                      <br />
                    </div>
                  );
                } else if (index === 2) {
                  return (
                    <div key={index}>
                      <b>
                        ...<i>Click para ver todos los recursos</i>
                      </b>
                    </div>
                  );
                }
              })}
            </>
          )}
          {rowData.Periodo !== "" && (
            <>
              <strong>Periodo: </strong> {rowData.Periodo} <br />
            </>
          )}
          {rowData.Distribuciones?.length > 0 && (
            <>
              <strong>Cecos: </strong>
              {rowData.Distribuciones?.map((e, index) => {
                if (index >= 0 && index <= 1) {
                  return (
                    <div key={index}>
                      ({e.Cecos.Codigo}) - {e.Cecos.Cliente} - %{e.Porcentaje}
                      <br />
                    </div>
                  );
                } else if (index === 2) {
                  return (
                    <div key={index}>
                      <b>
                        ...<i>Click para ver todos los cecos</i>
                      </b>
                    </div>
                  );
                }
              })}
            </>
          )}
          {rowData.Adjuntos?.length > 0 && (
            <>
              <strong>Adjuntos: </strong>
              {rowData.Adjuntos}
              <br />
            </>
          )}
          {rowData.Motivo !== "" && (
            <>
              <strong>Motivo: </strong> {rowData.Motivo}
              <br />
            </>
          )}
          {rowData.Comentarios !== "" && (
            <>
              <strong>Comentarios: </strong>
              {limitarCaracteres(rowData.Comentarios, 30)}
              <br />
            </>
          )}
        </div>
      );
    };

    const estadoBodyTemplate = (rowData) => {
      return (
        <div>
          {bandeja === true ? (
            rowData.Estado === "pendiente" ? (
              <>
                <button
                  onClick={() => {
                    setVisibleModalAceptar(true);
                    setNovedadSeleccionada(rowData);
                  }}
                  className="button-estado-modal green"
                >
                  <i
                    className="custom-target-icon pi pi-fw pi-check-circle"
                    style={{ width: "60px" }}
                    data-pr-tooltip="Aceptar"
                  ></i>
                </button>
                <br />
                <br />
                <button
                  onClick={() => {
                    setVisibleModalRechazar(true);
                    setNovedadSeleccionada(rowData);
                  }}
                  className="button-estado-modal red"
                >
                  <i
                    className="custom-target-icon pi pi-fw pi-times-circle"
                    style={{ width: "60px" }}
                    data-pr-tooltip="Rechazar"
                  ></i>
                </button>
              </>
            ) : (
              capitalizarPrimeraLetra(rowData.Estado)
            )
          ) : (
            capitalizarPrimeraLetra(rowData.Estado)
          )}
        </div>
      );
    };

    const dateFilterTemplate = (options) => {
      return (
        <div>
          <div style={{ marginBottom: "5px", fontSize: "12px" }}>
            Fecha Exacta*
          </div>
          <InputMask
            value={options.value}
            onChange={(e) => options.filterApplyCallback(e.value)}
            mask="9999-99-99"
            // slotChar="yyyy-mm-dd"
            placeholder="Año-Mes-Dia"
          />
          <div style={{ marginBottom: "5px", fontSize: "12px" }}>Año-Mes*</div>
          <InputMask
            value={options.value}
            onChange={(e) => options.filterApplyCallback(e.value)}
            mask="9999-99"
            // slotChar="yyyy-mm-dd"
            placeholder="Año-Mes"
          />
        </div>
      );
    };

    const statusFilterTemplate = (options) => {
      return (
        <Dropdown
          value={options.value}
          options={estados}
          onChange={(e) => options.filterApplyCallback(e.value)}
          // itemTemplate={statusItemTemplate}
          placeholder="Seleccione"
          className="p-column-filter"
          // showClear
          style={{ minWidth: "12rem" }}
        />
      );
    };

    const exportCSV = () => {
      const allAccepted = filteredData.every(
        (product) => product.Estado === "aceptada"
      );
      const allRejected = filteredData.every(
        (product) => product.Estado === "rechazada"
      );

      const includeRecursos = filteredData.some(
        (product) => product.Recursos && product.Recursos.length > 0
      );
      const includeComentarios = filteredData.some(
        (product) => product.Comentarios
      );
      const includeAdjuntos = filteredData.some(
        (product) => product.Adjuntos && product.Adjuntos.length > 0
      );
      const includeCecos = filteredData.some(
        (product) => product.Distribuciones && product.Distribuciones.length > 0
      );
      let headers = [];
      if (
        filteredData.every(
          (e) => e.Descripcion === "Asignación centro de costos"
        )
      ) {
        headers = [
          "Tipo de novedad",
          "ID",
          "Usuario",
          "Fecha de carga",
          "Estado",
          "Recurso",
          "Periodo",
          "Ceco",
          "Comentarios",
          "Adjuntos",
        ];
      } else if (filteredData.every((e) => e.Tipo === "PB")) {
        headers = [
          "Tipo de novedad",
          "ID",
          "Usuario",
          "Fecha de carga",
          "Estado",
          "Recurso",
          "Periodo",
          "Ceco",
          "Importe",
          "Comentarios",
          "Adjuntos",
        ];
      } else {
        headers = [
          "Tipo de novedad",
          "ID",
          "Usuario",
          "Fecha de carga",
          "Estado",
          "Periodo",
          "Sueldo basico actual",
          "Nuevo basico",
        ];

        if (includeRecursos) headers.push("Recurso");
        if (includeCecos) headers.push("Ceco");
        if (includeComentarios) headers.push("Comentarios");
        if (includeAdjuntos) headers.push("Adjuntos");
      }

      if (allAccepted) {
        headers.push("Aprobado por", "Motivo");
      } else if (allRejected) {
        headers.push("Rechazado por", "Motivo");
      }
      const csvContent = [
        headers,
        ...filteredData.map((product) => {
          let row = [];

          if (
            filteredData.every(
              (e) => e.Descripcion === "Asignación centro de costos"
            )
          ) {
            row = [
              product.Descripcion || "",
              product.IdSecuencial || "",
              product.Usuario || "",
              product.Fecha || "",
              product.Estado || "",
              product.Recursos?.map((e) => e.Recurso).join(", ") || "",
              product.Periodo || "",
              product.Distribuciones.map(
                (e) =>
                  "(" +
                  e.Cecos.Codigo +
                  ")-" +
                  e.Cecos.Proyecto +
                  " " +
                  e.Porcentaje +
                  "%"
              ).join(", ") || "",
              product.Comentarios || "",
              product.Adjuntos?.join(", ") || "",
            ];
          } else if (filteredData.every((e) => e.Tipo === "PB")) {
            row = [
              product.Descripcion || "",
              product.IdSecuencial || "",
              product.Usuario || "",
              product.Fecha || "",
              product.Estado || "",
              product.Recursos?.map((e) => e.Recurso).join(", ") || "",
              product.Periodo || "",
              product.Distribuciones.map(
                (e) =>
                  "(" +
                  e.Cecos.Codigo +
                  ")-" +
                  e.Cecos.Proyecto +
                  " " +
                  e.Porcentaje +
                  "%"
              ).join(", ") || "",
              "$" + product.ImporteTotal?.toLocaleString() || "",
              product.Comentarios || "",
              product.Adjuntos?.join(", ") || "",
            ];
          } else {
            row = [
              product.Descripcion || "",
              product.IdSecuencial || "",
              product.Usuario || "",
              product.Fecha || "",
              product.Estado || "",
              product.Periodo || "",
              product.Recursos?.map(
                (e) => "$" + e.SbActual.toLocaleString() + " "
              ).join(", ") || "",
              product.Recursos?.map(
                (e) => "$" + e.Importe.toLocaleString() + " "
              ).join(", ") || "",
            ];

            if (includeRecursos)
              row.push(
                product.Recursos?.map((e) => e.Recurso).join(", ") || ""
              );
            if (includeCecos)
              row.push(
                product.Distribuciones?.map(
                  (e) =>
                    "(" +
                    e.Cecos.Codigo +
                    ")-" +
                    e.Cecos.Proyecto +
                    " " +
                    e.Porcentaje +
                    "%"
                ).join(", ") || ""
              );
            if (includeComentarios) row.push(product.Comentarios || "");
            if (includeAdjuntos) row.push(product.Adjuntos?.join(", ") || "");
          }

          if (allAccepted || allRejected) {
            row.push(
              product.Workflow[0]?.Autorizador +
                "(" +
                product.Workflow[0]?.Aprobador +
                ")" +
                fechaHoraPopup(product.Workflow[0]?.Fecha).fechaHora || "",
              product.Motivo || ""
            );
          }
          return row;
        }),
      ];
      const ws = XLSX.utils.aoa_to_sheet(csvContent);
      ws["!cols"] = [
        { wpx: 100 },
        { wpx: 50 },
        { wpx: 120 },
        { wpx: 80 },
        { wpx: 50 },
        { wpx: 150 },
        { wpx: 50 },
        { wpx: 200 },
        { wpx: 80 },
        { wpx: 80 },
        { wpx: 250 },
        { wpx: 200 },
      ];

      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Hoja1");

      // Exportar el archivo Excel
      XLSX.writeFile(wb, "Equipo.xlsx");
    };

    useImperativeHandle(ref, () => ({
      ejecutarFuncion: exportCSV,
    }));

    return (
      <>
        {/* {!bandeja && (
        <Button
          icon={"pi pi-download"}
          // loading={isFetching}
          tooltip="Descarga novedades"
          style={{ height: "30px", width: "30px" }}
          onClick={exportCSV}
        />
      )} */}
        <ModalAceptada
          visible={visibleModalAceptar}
          setVisible={setVisibleModalAceptar}
          novedadSeleccionada={novedadSeleccionada}
        />
        <ModalRechazada
          visible={visibleModalRechazar}
          setVisible={setVisibleModalRechazar}
          novedadSeleccionada={novedadSeleccionada}
        />
        <Tooltip target=".custom-target-icon" />
        <DataTable
          onValueChange={(data) => setFilteredData(data)}
          ref={dt}
          value={allChecked ? novedades : novedadesFiltradas}
          scrollable
          paginator
          className="p-datatable-customers"
          rows={10}
          dataKey="IdSecuencial"
          filters={filters}
          filterDisplay="menu"
          loading={loading}
          responsiveLayout="scroll"
          emptyMessage="Sin resultados."
          style={{ fontFamily: "var(--main-font)" }}
          sortField="IdSecuencial"
          sortMode="single" //sortMode="multiple"
          sortOrder={-1}
        >
          <Column
            sortable
            field="IdSecuencial"
            header="ID"
            style={{ justifyContent: "center", minWidth: "2rem" }}
            filter
            filterPlaceholder="Buscar por ID"
            showAddButton={false}
            showFilterOperator={false}
            showFilterMatchModes={false}
            body={IDBodyTemplate}
          />
          <Column
            sortable
            field="Fecha"
            header="Fecha"
            style={{ minWidth: "12rem" }}
            filter
            filterElement={dateFilterTemplate}
            filterPlaceholder="Buscar por fecha"
            showAddButton={false}
            showFilterOperator={false}
            showFilterMatchModes={false}
          />
          {(usuario.Rol === "ta" ||
            usuario.Rol === "cultura" ||
            usuario.Rol === "po" ||
            usuario.Rol === "poFull") &&
            bandeja === true && (
              <Column
                sortable
                field="Usuario"
                header="Usuario"
                style={{ minWidth: "8rem" }}
                filter
                filterPlaceholder="Buscar por Usuario"
                showAddButton={false}
                showFilterOperator={false}
                showFilterMatchModes={false}
              />
            )}
          <Column
            sortable
            field="Periodo"
            header="Periodo"
            style={{ minWidth: "12rem" }}
            filter
            filterPlaceholder="Buscar por Periodo"
            showAddButton={false}
            showFilterOperator={false}
            showFilterMatchModes={false}
          />
          <Column
            sortable
            field="Estado"
            header="Estado"
            style={{ minWidth: "12rem" }}
            filter
            filterPlaceholder="Buscar por estado"
            showAddButton={false}
            showFilterOperator={false}
            showFilterMatchModes={false}
            body={estadoBodyTemplate}
            filterElement={statusFilterTemplate}
          />
          <Column
            field="Resumen"
            header="Resumen"
            style={{
              justifyContent: "start",
              minWidth: "400px",
              whiteSpace: "pre-line",
              textAlign: "start",
            }}
            body={resumenBodyTemplate}
            filter
            filterPlaceholder="Buscar por resumen"
            showAddButton={false}
            showFilterOperator={false}
            showFilterMatchModes={false}
          />
        </DataTable>
        <PopUpResumen
          resumenVisible={resumenVisible}
          setResumenVisible={setResumenVisible}
          rowDataSelection={rowDataSelection}
          tipoDeNovedad={tipoDeNovedad}
          setTipoDeNovedad={setTipoDeNovedad}
        />
      </>
    );
  }
);

export default TableRecursos;
