export const getReporteHorasExcel = async (fullUrl, token, nombreArchivo, showToast) => {
  const toastCall = () => {
    return showToast({
      severity: "error",
      summary: "Error al descargar",
      detail: "No se pudo descargar correctamente el reporte.",
      life: 5000,
    });
  }
     
  const options = {
    method: "GET",
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    },
  };

  return await fetch(fullUrl, options)
    .then((response) => {
      if (!response.ok) {
        toastCall();
        return response.ok;
      }

      return response.blob();
    })
    .then((blob) => {
      if (!blob) {
        toastCall();
        return;
      }
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", nombreArchivo);
      document.body.appendChild(link);
      link.click();
      link.parentNode?.removeChild(link);
      window.URL.revokeObjectURL(url);
    })
    .catch((error) => {
      toastCall();
      console.log(`Error al obtener el archivo: ${error.message}`);
      return error;
    });
};
