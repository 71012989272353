import React, { useContext, useEffect, useState } from "react";
import { Dropdown } from "primereact/dropdown";
import { SpeedDial } from "primereact/speeddial";
import { Tooltip } from "primereact/tooltip";
import { DialogExportExcel } from "./DialogExportExcel";
import { AuthContext } from "../../contexts/AuthContext";
import { NovedadesContext } from "../../contexts/NovedadesContext";
import CecosTable from "./CecosTable";
import DownloadReportButton from "./DownloadReportButton";
import Menu from "../../components/Menu";
import RecursosTable from "./RecursosTable";
import { EXCEL_TYPES } from "./types";
import { downloadFileFromBlob } from "../../helpers/downloadFileFromBlob";
import logoitp from "../../imagenes/logo.png";
import "./informes.css";
import { HabilidadesContext } from "../../contexts/HabilidadesContext";
import { Button } from "primereact/button";

const dropOptions = [
  { label: "Recursos", id: "recursos" },
  { label: "Cecos", id: "cecos" },
];

const Informes = () => {
  const {
    getRecursos,
    recursosArray,
    getCecos,
    cecos,
    loadingExcel,
    getRecursosReport,
    getCecosReport,
  } = useContext(NovedadesContext);
  const { getExcelMatrizHabilidades, isLoading } =
    useContext(HabilidadesContext);
  const { usuario } = useContext(AuthContext);
  const [optList, setOptList] = useState(dropOptions[0]);
  const [xlsDialogVis, setXlsDialogVis] = useState(false);
  const [xlsTipo, setXlsTipo] = useState(0);

  useEffect(() => {
    getRecursos();
    getCecos();
  }, []);

  const speedDialItems = [
    {
      label: "Informe Exportable Clientes",
      icon: "pi pi-file-export",
      command: () => {
        setXlsTipo(EXCEL_TYPES.clientes);
        setXlsDialogVis(true);
      },
      visible: usuario.Rol === "admin",
    },
    {
      label: "Informe Exportable Proveedores",
      icon: "pi pi-file-export",
      command: () => {
        setXlsTipo(EXCEL_TYPES.proveedores);
        setXlsDialogVis(true);
      },
      visible: usuario.Rol === "admin",
    },
    {
      label: "Informe Exportable PO",
      icon: "pi pi-file-export",
      command: () => {
        setXlsTipo(EXCEL_TYPES.po);
        setXlsDialogVis(true);
      },
      visible: usuario.Rol === "po" || usuario.Rol === "poFull",
    },
    {
      label: "Informe Recursos PO",
      icon: "pi pi-file",
      command: () => {
        setXlsTipo(EXCEL_TYPES.recursosPo);
        setXlsDialogVis(true);
      },
      visible:
        usuario.Rol === "admin" ||
        usuario.Rol === "po" ||
        usuario.Rol === "poFull",
    },
    {
      label: "Informe Recursos General",
      icon: "pi pi-file",
      command: async () => {
        await handleReportDownload(EXCEL_TYPES.recursosGeneral);
      },
      visible: usuario.Rol !== "",
    },
    {
      label: "Informe Cecos General",
      icon: "pi pi-file-export",
      command: async () => {
        await handleReportDownload(EXCEL_TYPES.cecosGeneral);
      },
      visible: usuario.Rol !== "",
    },
  ];

  const handleReportDownload = async (tipoInforme) => {
    let dataBlob;
    let fileName = "informe-";
    if (tipoInforme === EXCEL_TYPES.recursosGeneral) {
      fileName += "recursos";
      dataBlob = await getRecursosReport();
    } else if (tipoInforme === EXCEL_TYPES.cecosGeneral) {
      fileName += "cecos";
      dataBlob = await getCecosReport();
    }
    downloadFileFromBlob(dataBlob, fileName);
  };

  return (
    <div className="container">
      <Menu />
      <div className="encabezado">
        <h3 className="titulo">Portal de novedades</h3>
        <img className="imagen_encabezado" src={logoitp} alt="" />
      </div>
      <div className="titulo_novedades" style={{ marginBottom: "20px" }}>
        <div>
          <h3 className="">Informes y consultas</h3>
          {usuario.Rol !== "" && (
            <>
              <div className="speeddial-tooltip-demo">
                <Tooltip
                  target=".speeddial-tooltip-demo .speeddial-right .p-speeddial-action"
                  position="top"
                />
                <SpeedDial
                  className="speed_dial speeddial-right"
                  model={speedDialItems}
                  direction="right"
                />
              </div>
            </>
          )}
        </div>
      </div>
      <DialogExportExcel
        usuario={usuario}
        xlsTipo={xlsTipo}
        opciones={xlsTipo !== EXCEL_TYPES.recursos}
        xlsDialogVis={xlsDialogVis}
        setXlsDialogVis={setXlsDialogVis}
      />
      <div className="input-container div_dropdown-informes descarga-matriz">
        <span className="p-float-label">
          <Dropdown
            inputId="option"
            name="option"
            className="dropdown-informes"
            value={optList}
            onChange={(e) => setOptList(e.value)}
            options={dropOptions}
            optionLabel="label"
          />
          <label htmlFor="option" style={{ fontSize: "13px" }}>
            Seleccione una lista
          </label>
        </span>
        {optList.label === "Recursos" &&
          (usuario.Rol === "po" ||
            usuario.Rol === "poFull" ||
            usuario.Rol === "admin") && (
            <Button
              icon="pi pi-download"
              className="button-descarga-matriz"
              onClick={() => getExcelMatrizHabilidades()}
              disabled={isLoading}
            >
              Descargar matriz de habilidades
            </Button>
          )}
        {/*<DownloadReportButton
          isLoading={loadingExcel}
          onClick={handleReportBtnClick}
        />*/}
      </div>
      {optList.id === "recursos" && (
        <RecursosTable dataValues={recursosArray} />
      )}
      {optList.id === "cecos" && <CecosTable dataValues={cecos} />}
    </div>
  );
};

export default Informes;
