import { AutoComplete } from "primereact/autocomplete";
import { RadioButton } from "primereact/radiobutton";
import { useState } from "react";
import "../styles/Calendar.css";
import { useContext } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import { getReporteHorasExcel } from "../services/getReporteHoras.service.js";

const ModalReporte = ({ selectProyecto, cancel, showToast }) => {
  const [proyectoSeleccionado, setProyectoSeleccionado] = useState("");
  const [fechaSeleccionada, setFechaSeleccionada] = useState("");
  const [sugerencias, setSugerencias] = useState([]);
  const [quieroProyecto, setQuieroProyecto] = useState(true);
  const [quieroRecurso, setQuieroRecurso] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const { getTokenFromSessionStorage } = useContext(AuthContext);

  const searchProyectos = (event) => {
    const proyectosFiltrados = selectProyecto.filter((proyecto) =>
      proyecto.name.toLowerCase().includes(event.query.toLowerCase())
    );
    const sugerencias = proyectosFiltrados.map((proyecto) => ({
      name: proyecto.name,
      _id: proyecto._id,
      codigo: proyecto.codigo,
    }));
    setSugerencias(sugerencias);
  };

  const handleBlur = () => {
    if (
      !sugerencias.some(
        (sugerencia) => sugerencia.name === proyectoSeleccionado.name
      )
    ) {
      setProyectoSeleccionado("");
    }
  };

  const handleProyectoChange = (event) => {
    setProyectoSeleccionado(event.target.value);
  };

  const handleSelect = (e) => {
    setProyectoSeleccionado(e.value);
  };

  function getCurrentMonth() {
    const now = new Date();
    const year = now.getFullYear();
    const month = (now.getMonth() + 1).toString().padStart(2, "0");
    return `${year}-${month}`;
  }

  const descargaExcel = async () => {
    if (fechaSeleccionada) {
      const [year, month] = fechaSeleccionada.split("-");
      const fechaFormateada = `${month}-${year}`;

      const apiUrl = process.env.REACT_APP_URL_TIMESHEET;
      let endpoint;
      let nombreArchivo;

      if (quieroProyecto && proyectoSeleccionado) {
        endpoint = `/api/users/exporta-detalle-horas-cecos/${proyectoSeleccionado.codigo}/${fechaFormateada}`;
        nombreArchivo = `detalle_horas_${proyectoSeleccionado.name}_${fechaFormateada}.xlsx`;
      } else if (quieroRecurso) {
        endpoint = `/api/users/exporta-detalle-horas-cecos-mes/${fechaFormateada}`;
        nombreArchivo = `detalle_horas_${fechaFormateada}.xlsx`;
      }

      const fullUrl = apiUrl + endpoint;
      const key = process.env.REACT_APP_TOKEN_KEY;
      const token = getTokenFromSessionStorage(key);

      setIsFetching(true);
      await getReporteHorasExcel(fullUrl, token, nombreArchivo, showToast);
      cancel(false);
      setFechaSeleccionada("");
      setProyectoSeleccionado("");
      setIsFetching(false);
    }
  };

  return (
    <>
      x
      <div className="overlay">
        <div className="modal-reporte">
          <span
            className="material-symbols-outlined"
            style={{
              cursor: "pointer",
              fontSize: "20px",
              display: "flex",
              justifyContent: "flex-end",
            }}
            onClick={() => {
              cancel(false);
              setProyectoSeleccionado("");
              setFechaSeleccionada("");
            }}
          >
            close
          </span>
          <div>
            <h2 className="color-button">Descarga el reporte</h2>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              marginTop: "18px",
            }}
          >
            <div style={{ paddingRight: "14px" }}>
              <RadioButton
                inputId="ingredient1"
                name="proyecto"
                checked={quieroProyecto}
                onChange={(e) => {
                  setQuieroProyecto(true);
                  setQuieroRecurso(false);
                }}
              />
              <label htmlFor="ingredient1" className="ml-2">
                Por ceco
              </label>
            </div>
            <div>
              <RadioButton
                inputId="ingredient1"
                name="recursos"
                checked={quieroRecurso}
                onChange={(e) => {
                  setQuieroRecurso(true);
                  setQuieroProyecto(false);
                }}
              />
              <label htmlFor="ingredient1" className="ml-2">
                Por recursos
              </label>
            </div>
          </div>
          {quieroProyecto && (
            <div>
              <p
                style={{
                  margin: 0,
                  display: "flex",
                  justifyContent: "flex-start",
                  marginTop: "18px",
                }}
              >
                Centro de costo*
              </p>
              <AutoComplete
                value={proyectoSeleccionado}
                suggestions={sugerencias}
                completeMethod={searchProyectos}
                field="name"
                className="select-proyecto-calendar"
                style={{ marginBottom: "0px" }}
                dropdown
                onChange={handleProyectoChange}
                onSelect={handleSelect}
                onBlur={handleBlur}
                placeholder="Selecciona el ceco"
              />
            </div>
          )}

          <div style={{ marginTop: "20px" }}>
            <p
              style={{
                margin: 0,
                display: "flex",
                justifyContent: "flex-start",
              }}
            >
              Mes*
            </p>
            <input
              type="month"
              className="input-calendar-month"
              value={fechaSeleccionada}
              onChange={(e) => setFechaSeleccionada(e.target.value)}
              max={getCurrentMonth()}
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "30px",
            }}
          >
            <button
              className="button-modal-calendar"
              style={{ width: "40%" }}
              disabled={
                isFetching ||
                fechaSeleccionada === "" ||
                (proyectoSeleccionado === "" && !quieroRecurso)
              }
              onClick={() => descargaExcel()}
            >
              {isFetching ? (
                <>
                  Cargando.
                  <span className="dots"></span>
                </>
              ) : (
                "Descargar"
              )}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ModalReporte;
