import React, { useState, useEffect, useContext } from "react";
import { Card } from "primereact/card";
import { SelectButton } from "primereact/selectbutton";
import "./calculoTalento.css";
import { useNavigate } from "react-router-dom";
import { CotizadorContext } from "../../../contexts/CotizadorContext";
import { Tooltip } from "primereact/tooltip";
import ModalHoras from "../TablaSeleccionTalento/ModalHoras";
//import { moneda } from "../DatosDelProyecto/mockupMoneda";

const CalculoTalento = ({
  data,
  open,
  isButtonDisabled,
  selectedTipoProyecto,
  totalHorasTalento,
}) => {
  const {
    datosProyectos,
    setDataCalculoTalento,
    setFacturaAEmitir,
    totalOtrosCostos,
    horasDelProyecto,
    tipoMoneda,
  } = useContext(CotizadorContext);

  const [openModalHoras, setOpenModalHoras] = useState(false);
  const [valores, setValores] = useState({
    rentabilidadTotal: 0,
    rentabilidadMensual: 0,
    facturaEmitir: 0,
    subtotalCostos: 0,
    // totalOtrosCostos: 0,
    costoTotal: 0,
    facturaEmitirTotal: 0,
    rentabilidadTotalOriginal: 0,
    facturaEmitirOriginal: 0,
    facturaEmitirTotalOriginal: 0,
    subtotalCostosOriginal: 0,
    costoTotalOriginal: 0,
    sumaTotalOtrosCostos: 0,
    simboloMoneda: datosProyectos.moneda,
  });
  const [duracionMeses, setDuracionMeses] = useState(0);

  //const options = tipoMoneda;
  const monedaSeleccionada = tipoMoneda.find(
    (moneda) => moneda.value === datosProyectos?.moneda
  );
  const monedaArgentina = tipoMoneda?.find((moneda) => moneda.value === "$");
  const options = [monedaSeleccionada, monedaArgentina];
  const calcularDuracionEnDias = (duracion) => {
    const duracionMap = {
      "1 mes": 30,
      "2 meses": 60,
      "3 meses": 90,
      "4 meses": 120,
      "5 meses": 150,
      "6 meses": 180,
      "7 meses": 210,
      "8 meses": 240,
      "9 meses": 270,
      "10 meses": 300,
      "11 meses": 330,
      "12 meses": 360,
      "13 meses": 390,
      "14 meses": 420,
      "15 meses": 450,
      "16 meses": 480,
      "17 meses": 510,
      "18 meses": 540,
      "19 meses": 570,
      "20 meses": 600,
      "21 meses": 630,
      "22 meses": 660,
      "23 meses": 690,
      "24 meses": 720,
    };

    return duracionMap[duracion] || 0;
  };

  const truncateValores = (num, maxLength) => {
    const numString = num?.toString();
    if (numString?.length > maxLength) {
      return numString?.slice(0, maxLength) + "...";
    }
    return numString;
  };

  const navigate = useNavigate();
  useEffect(() => {
    let facturaEmitir = 0;
    let facturaEmitirOriginal = 0;
    let costoTotal = 0;
    let costoTotalOriginal = 0;
    let facturaEmitirTotal = 0;
    let facturaEmitirTotalOriginal = 0;
    let subtotalCostosOriginal = 0;
    let rentabilidadTotal = 0;
    let rentabilidadMensual = 0;
    let rentabilidadMensualOriginal = 0;
    let rentabilidadTotalOriginal = 0;

    const selectedMoneda = tipoMoneda.find(
      (m) => m.value === valores?.simboloMoneda
    );
    const price = selectedMoneda ? selectedMoneda.price : 1;
    const simboloMoneda = selectedMoneda ? selectedMoneda.value : "$";
    const sumaTotalOtrosCostos = totalOtrosCostos?.suma
      ? parseFloat((totalOtrosCostos.suma / price).toFixed(2))
      : 0;
    if (selectedTipoProyecto.name === "Llave en mano") {
      facturaEmitirOriginal =
        data?.reduce((acc, curr) => acc + curr.ventaTotal, 0) * duracionMeses ||
        0;
      facturaEmitir = parseFloat((facturaEmitirOriginal / price).toFixed(2));
    } else if (selectedTipoProyecto.name === "Servicios") {
      facturaEmitirOriginal =
        data?.reduce((acc, curr) => acc + curr.ventaTotal, 0) || 0;
      facturaEmitir = parseFloat((facturaEmitirOriginal / price).toFixed(2));
      facturaEmitirTotalOriginal = parseFloat(
        (facturaEmitirOriginal * duracionMeses).toFixed(2)
      );
      facturaEmitirTotal = parseFloat(
        (facturaEmitir * duracionMeses).toFixed(2)
      );
    } else {
      facturaEmitirOriginal =
        data?.reduce((acc, curr) => acc + curr.subTotal, 0) || 0;
      facturaEmitir = parseFloat((facturaEmitirOriginal / price).toFixed(2));
      facturaEmitirTotalOriginal = parseFloat(
        (facturaEmitirOriginal * duracionMeses).toFixed(2)
      );
      facturaEmitirTotal = parseFloat(
        (facturaEmitir * duracionMeses).toFixed(2)
      );
    }
    if (
      selectedTipoProyecto.name === "Servicios" ||
      selectedTipoProyecto.name === "Llave en mano"
    ) {
      subtotalCostosOriginal =
        data?.reduce((acc, curr) => acc + curr.subtotalCosto, 0) || 0;
    } else {
      subtotalCostosOriginal =
        data?.reduce(
          (acc, curr) =>
            acc + curr.costoUnitario + curr.sueldoBruto * curr.cantidad,
          0
        ) || 0;
    }

    const subtotalCostos = parseFloat(
      (subtotalCostosOriginal / price).toFixed(2)
    );

    costoTotalOriginal = subtotalCostosOriginal + sumaTotalOtrosCostos || 0;
    costoTotal = subtotalCostos + sumaTotalOtrosCostos || 0;

    if (selectedTipoProyecto.name !== "Llave en mano") {
      rentabilidadMensual = facturaEmitir - costoTotal || 0;
      rentabilidadMensualOriginal =
        facturaEmitirOriginal - costoTotalOriginal || 0;

      rentabilidadTotal =
        rentabilidadMensual *
        (datosProyectos?.duracion?.name
          ? parseInt(datosProyectos.duracion.name.match(/\d+/)[0])
          : 1);
      rentabilidadTotalOriginal =
        rentabilidadMensualOriginal *
        (datosProyectos?.duracion?.name
          ? parseInt(datosProyectos.duracion.name.match(/\d+/)[0])
          : 1);
    } else {
      rentabilidadTotal = facturaEmitir - costoTotal;
      rentabilidadTotalOriginal =
        rentabilidadMensualOriginal - costoTotalOriginal;

      rentabilidadMensual = rentabilidadTotal / duracionMeses;
      rentabilidadMensualOriginal = rentabilidadMensualOriginal / duracionMeses;
    }

    const duracion = calcularDuracionEnDias(datosProyectos?.duracion.name) / 30;

    const porcentajeTotal =
      facturaEmitir !== 0
        ? Math.floor((rentabilidadTotal / (facturaEmitir * duracion)) * 100)
        : 0;

    const porcentajeMensual =
      facturaEmitir !== 0
        ? Math.floor((rentabilidadMensual / facturaEmitir) * 100)
        : 0;

    setValores({
      rentabilidadTotal,
      rentabilidadMensual,
      facturaEmitir,
      facturaEmitirTotal,
      subtotalCostos,
      costoTotal,
      porcentajeTotal,
      porcentajeMensual,
      simboloMoneda,
      sumaTotalOtrosCostos,
      // Valores originales sin dividir
      rentabilidadTotalOriginal,
      rentabilidadMensualOriginal,
      facturaEmitirOriginal,
      facturaEmitirTotalOriginal,
      subtotalCostosOriginal,
      costoTotalOriginal,
      price,
    });
  }, [data, valores.simboloMoneda, totalOtrosCostos]);

  useEffect(() => {
    datosProyectos.horas = horasDelProyecto;
  }, [horasDelProyecto]);

  useEffect(() => {
    setFacturaAEmitir(valores?.facturaEmitirOriginal);
  }, [valores.facturaEmitirOriginal]);

  useEffect(() => {
    setDuracionMeses(parseInt(datosProyectos.duracion.name.match(/\d+/)[0]));
  }, [datosProyectos.duracion]);

  const handleContinuar = () => {
    if (
      (selectedTipoProyecto.name === "Llave en mano" ||
        selectedTipoProyecto.name === "Servicios") &&
      datosProyectos.horas !== null &&
      datosProyectos.horas !== totalHorasTalento
    ) {
      setOpenModalHoras(true);
    } else {
      setDataCalculoTalento(valores);
      navigate("/cotizador/resumen-cotizacion");
    }
  };

  return (
    <div className="calculo-container">
      <Tooltip target=".custom-target-card" />
      <Card title="Rentabilidad Total" className="card-item">
        <div style={{ display: "flex" }}>
          <h3
            className="custom-target-card"
            data-pr-position="top"
            data-pr-tooltip={`Rentabilidad Total: $${valores?.rentabilidadTotal.toLocaleString()}`}
          >
            {valores?.simboloMoneda} {""}
            {truncateValores(valores?.rentabilidadTotal.toLocaleString(), 8)}
          </h3>
          <div
            className={`porcentaje-rentabilidad ${
              valores?.porcentajeTotal < 0 ? "rojo" : ""
            }`}
          >
            {valores?.porcentajeTotal}%
          </div>
        </div>
      </Card>
      <Card title="Rentabilidad Mensual" className="card-item">
        <div style={{ display: "flex" }}>
          <h3
            className="custom-target-card"
            data-pr-position="top"
            data-pr-tooltip={`Rentabilidad Mensual: $${valores?.rentabilidadMensual.toLocaleString()}`}
          >
            {valores?.simboloMoneda} {""}
            {truncateValores(valores?.rentabilidadMensual.toLocaleString(), 8)}
          </h3>
          <div
            className={`porcentaje-rentabilidad ${
              valores?.porcentajeMensual < 0 ? "rojo" : ""
            }`}
          >
            {valores?.porcentajeMensual}%
          </div>
        </div>
      </Card>
      <Card
        title={
          selectedTipoProyecto.name === "Tarifa" ||
          selectedTipoProyecto.name === "Contractor" ||
          selectedTipoProyecto.name === "Servicios"
            ? "Subtotal Costos"
            : "Costos proyecto"
        }
        className="card-item"
      >
        <h3
          className="custom-target-card"
          data-pr-position="top"
          data-pr-tooltip={
            selectedTipoProyecto.name === "Tarifa" ||
            selectedTipoProyecto.name === "Contractor" ||
            selectedTipoProyecto.name === "Servicios"
              ? `Subtotal Costos: $${valores?.subtotalCostos?.toLocaleString()}`
              : `Costos proyecto: $${valores?.subtotalCostos?.toLocaleString()}`
          }
        >
          {valores?.simboloMoneda} {""}
          {truncateValores(valores?.subtotalCostos.toLocaleString(), 11)}
        </h3>
      </Card>
      <Card
        title="Otros Costos"
        className="card-item"
        style={{ position: "relative" }}
      >
        <h3
          className="custom-target-card"
          data-pr-position="top"
          data-pr-tooltip={`Otros Costos: $${
            valores.sumaTotalOtrosCostos?.toLocaleString() || 0
          }`}
        >
          {valores?.simboloMoneda} {""}
          {truncateValores(
            valores.sumaTotalOtrosCostos?.toLocaleString(),
            11
          ) || 0}
        </h3>
        <i
          onClick={() => open(true)}
          alt="Editar"
          className="pi pi-pencil"
          style={{
            position: "absolute",
            top: 17,
            left: 100,
            cursor: "pointer",
            color: "rgba(4, 74, 205, 1)",
            fontSize: "15px",
          }}
        />
      </Card>
      <Card
        title={
          selectedTipoProyecto.name === "Tarifa" ||
          selectedTipoProyecto.name === "Contractor" ||
          selectedTipoProyecto.name === "Servicios"
            ? "Costo Mensual"
            : "Costos Total"
        }
        className="card-item"
      >
        <h3
          className="custom-target-card"
          data-pr-position="top"
          data-pr-tooltip={` ${
            selectedTipoProyecto.name === "Tarifa" ||
            selectedTipoProyecto.name === "Contractor"
              ? "Costo Mensual:"
              : "Costos Total:"
          } $${valores?.costoTotal.toLocaleString()}`}
        >
          {valores?.simboloMoneda} {""}
          {truncateValores(valores?.costoTotal.toLocaleString(), 11)}
        </h3>
      </Card>
      {selectedTipoProyecto.name !== "Servicios" &&
        selectedTipoProyecto.name !== "Llave en mano" && (
          <Card title="Factura a emitir (Mensual)" className="card-item">
            <h3
              className="custom-target-card"
              data-pr-position="top"
              data-pr-tooltip={`Factura a emitir (Mensual): $${valores?.facturaEmitir.toLocaleString()}`}
            >
              {valores?.simboloMoneda} {""}
              {truncateValores(valores?.facturaEmitir.toLocaleString(), 11)}
            </h3>
          </Card>
        )}
      <Card
        title={
          selectedTipoProyecto.name === "Tarifa" ||
          selectedTipoProyecto.name === "Contractor"
            ? "Factura total"
            : selectedTipoProyecto.name === "Servicios"
            ? "Factura a emitir (Mensual)"
            : "Factura a emitir"
        }
        className="card-item"
      >
        <h3
          className="custom-target-card"
          data-pr-position="top"
          data-pr-tooltip={`${
            selectedTipoProyecto.name === "Tarifa"
              ? "Factura total:"
              : selectedTipoProyecto.name === "Servicios"
              ? "Factura a emitir (Mensual):"
              : "Factura a emitir (Total):"
          } $${(selectedTipoProyecto.name === "Tarifa" ||
          selectedTipoProyecto.name === "Contractor"
            ? valores?.facturaEmitirTotal
            : valores?.facturaEmitir
          )?.toLocaleString()}`}
        >
          {valores?.simboloMoneda} {""}
          {selectedTipoProyecto.name === "Tarifa" ||
          selectedTipoProyecto.name === "Contractor"
            ? truncateValores(
                (valores?.facturaEmitirTotal).toLocaleString(),
                11
              )
            : truncateValores((valores?.facturaEmitir).toLocaleString(), 11)}
        </h3>
      </Card>
      <div
        className="card-item"
        style={{
          ...(datosProyectos.moneda === "$" && {
            display: "flex",
            alignItems: "center",
          }),
        }}
      >
        {datosProyectos.moneda !== "$" && (
          <SelectButton
            options={options}
            optionLabel="value"
            value={valores?.simboloMoneda}
            onChange={(e) => {
              setValores({
                ...valores,
                simboloMoneda: e.value,
              });
            }}
            className="selectButtonMonedas"
          />
        )}
        <button
          onClick={handleContinuar}
          className="continuar-button"
          disabled={isButtonDisabled}
        >
          CONTINUAR
        </button>
      </div>
      {openModalHoras && (
        <ModalHoras
          cancel={setOpenModalHoras}
          totalHorasTalento={totalHorasTalento}
        />
      )}
    </div>
  );
};

export default CalculoTalento;
