import { holidaysMock2024 } from "./holidays.mock";

export const getHolidays = async () => {
  const data = holidaysMock2024;
  return mapArrayData(data);
};

const mapArrayData = (data) => {
  const newData = data.map((x) => mapDataApiToHoliday(x));
  return newData;
};

const mapDataApiToHoliday = (apiData) => {
  return {
    ...apiData,
    allDay: true,
    holiday: true,
  };
};
