/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-no-comment-textnodes */
import { Calendar, dayjsLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { useContext, useRef, useState, useEffect } from "react";
import dayjs from "dayjs";
import "dayjs/locale/es";
import { MdDelete } from "react-icons/md";
import { Tooltip } from "react-tooltip";
import { mockupTipo } from "../constants/mockuptipo";
import { mockupHorario } from "../constants/mockuphorario";
import { AuthContext } from "../../../contexts/AuthContext";
import { Toast } from "primereact/toast";
import { AutoComplete } from "primereact/autocomplete";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Dropdown } from "primereact/dropdown";
import { FileUpload } from "primereact/fileupload";
import ModalReporte from "./ModalReporte";
import { NovedadesContext } from "../../../contexts/NovedadesContext";
import { useNavigate } from "react-router-dom";
import ReporteUsuario from "./ReporteUsuario";
import { getHolidays } from "../services/getHolidays.service";
import {
  formatDateToISO,
  formatDateToReverse,
  formatDateToReverseMesDiaAño,
} from "../helpers/formatDate";
import "../styles/Calendar.css";

dayjs.locale("es");
const tamañoMaxArchivo = 3 * 1000 * 1000;

const Calendario = () => {
  const [events, setEvents] = useState([]);
  const [holidays, setHolidays] = useState([]);
  const [preCarga, setPreCarga] = useState([]);

  const [centroDeCostos, setCentroDeCostos] = useState([]);
  const [selectedCC, setSelectedCC] = useState("");
  const [filteredCC, setFilteredCC] = useState([]);

  const [proyectos, setProyectos] = useState([]);
  const [selectedProyecto, setSelectedProyecto] = useState(null);
  const [filteredProyectos, setFilteredProyectos] = useState([]);

  const [selectTarea, setSelectTarea] = useState([]);
  const [selectTipo] = useState(mockupTipo);
  const [selectHorario] = useState(mockupHorario);
  const [fechaInicio, setFechaInicio] = useState("");
  const [fechaFin, setFechaFin] = useState("");
  const [tipoHora, setTipoHora] = useState("");
  const [periodo, setPeriodo] = useState("");
  const [horario, setHorario] = useState("");
  const [archivo, setArchivo] = useState([]);
  const [fechaElegida, setFechaElegida] = useState("");
  const [agregarHabilitado, setAgregarHabilitado] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalDetails, setModalDetails] = useState("");
  const [modalHours, setModalHours] = useState("");
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [editingEventId, setEditingEventId] = useState(null);
  const [editingEventDetalleId, setEditingEventDetalleId] = useState(null);
  const [editHabilitado, setEditHabilitado] = useState(false);
  const [editCarga, setEditCarga] = useState(false);
  const [deletingEventId, setDeletingEventId] = useState(null);
  const [totalHoursLoaded, setTotalHoursLoaded] = useState(0);
  const [horasPorProyecto, setHorasPorProyecto] = useState([]);
  const [currentMonth, setCurrentMonth] = useState(dayjs());
  const [horasDiarias, setHorasDiarias] = useState(true);
  const [horasExtras, setHorasExtras] = useState(false);
  const [esLicencia, setEsLicencia] = useState(false);
  const [esHorasDiarias, setEsHorasDiarias] = useState(false);
  const [isModalVerMasOpen, setIsModalVerMasOpen] = useState(false);
  const [modalMas, setModalMas] = useState([]);
  const [isOpenModaleReporte, setIsOpenModalReporte] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [formChanges, setFormChanges] = useState({
    title: "",
    details: "",
    hours: 0,
    ccName: "",
    project: "",
    fechaFin: "",
  });
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] =
    useState(false);
  const localizer = dayjsLocalizer(dayjs);
  const navigate = useNavigate();

  const { getTokenFromSessionStorage, usuario, setLoadGoogle, setUsuario } =
    useContext(AuthContext);
  const { periodos, getPeriodos } = useContext(NovedadesContext);
  const key = process.env.REACT_APP_TOKEN_KEY;
  const toastRef = useRef(null);

  useEffect(() => {
    getPeriodos();
  }, []);

  const fetchDataGet = async (url, setter) => {
    try {
      const apiUrl = process.env.REACT_APP_URL_TIMESHEET;
      const fullUrl = apiUrl + url;
      const token = getTokenFromSessionStorage(key);
      const options = {
        method: "GET",
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
      };
      const response = await fetch(fullUrl, options);

      if (response.ok) {
        const data = await response.json();
        const eventsWithDateObjects = data.map((event) => {
          const start = new Date(
            event.start.replace(/-/g, "/").replace(/T.+/, "")
          );
          const end = new Date(event.end.replace(/-/g, "/").replace(/T.+/, ""));
          end.setHours(end.getHours() + 1);
          return {
            ...event,
            start,
            end,
          };
        });
        setter(eventsWithDateObjects);
      } else if (response.status === 401) {
        localStorage.clear();
        setUsuario(null);
        setLoadGoogle(false);
        navigate("/login", { replace: true });
        return [];
      } else {
        toastRef.current.show({
          severity: "error",
          summary: "Error al traer las tareas",
          detail: "No se pudo cargar las tareas correctamente.",
          life: 5000,
        });
      }
    } catch (error) {
      toastRef?.current?.show({
        severity: "error",
        summary: "Error al traer las tareas",
        detail: "No se pudo cargar las tareas correctamente.",
        life: 5000,
      });
    }
  };

  const fetchData = async (url, setter) => {
    try {
      const apiUrl = process.env.REACT_APP_URL_TIMESHEET;
      const fullUrl = apiUrl + url;
      const token = getTokenFromSessionStorage(key);
      const options = {
        method: "GET",
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
      };
      const response = await fetch(fullUrl, options);

      if (response.ok) {
        const data = await response.json();
        setter(data);
      }
    } catch (error) {}
  };

  useEffect(() => {
    setFechaFin(fechaInicio)
  },[fechaInicio])

  useEffect(() => {
    fetchDataGet("/api/workdays/date/" + currentMonth.$d, setEvents);
    fetchData("/api/tasks", setSelectTarea);
    fetchData("/api/cecos", setCentroDeCostos);
    if (selectedCC?.codigo) {
      fetchData("/api/projects/ceco/" + selectedCC?.codigo, setProyectos);
    }
    fetchData("/api/cecos/hours/" + currentMonth.$d, setHorasPorProyecto);
  }, [currentMonth, selectedCC]);

  const fetchHolidays = async () => {
    const data = await getHolidays();
    setHolidays(data);
  };

  useEffect(() => {
    fetchHolidays();
  }, []);

  //dependiendo en q dia y lugar hayas clickeado responde de diferente manera para abrir el modal con datos cargadados o no.
  const handleCalendarClickSlot = ({ start }) => {
    setSelectedSlot(start);
    setEditingEventId(null);

    const existingEvent = events.find((event) =>
      dayjs(event.start).isSame(start, "hour")
    );

    if (existingEvent) {
      setDeletingEventId(existingEvent);
      setShowModal(true);
      setModalTitle("");
      setModalDetails("");
      setModalHours("");
     // setFechaFin("");
      setSelectedCC("");
      setSelectedProyecto(null);
      setTipoHora("");
      setHorario("");
      setPreCarga([]);
      setEditCarga(false);
      setEsLicencia(false);
      setEsHorasDiarias(false);
      setEditCarga(false);
      setFechaInicio(formatDateToISO(start));
      setIsOpenModalReporte(false);
      setHorasExtras(false);
      setHorasDiarias(true);
    } else {
      setShowModal(true);
      setModalTitle("");
      setModalDetails("");
      setModalHours("");
      setSelectedCC("");
      setSelectedProyecto(null);
      setTipoHora("");
      setHorario("");
 
      setPreCarga([]);
      setEditCarga(false);
      setIsModalVerMasOpen(false);
      setEsLicencia(false);
      setEsHorasDiarias(false);
      setFechaInicio(formatDateToISO(start));
      setEditCarga(false);
      setIsOpenModalReporte(false);
      setHorasExtras(false);
      setHorasDiarias(true);
    }
    //     setFechaFin("");
  };

  const handleCCChange = (e) => {
    setSelectedCC(e.target.value);
  };
  const handleCCSelect = (e) => {
    setSelectedCC(e.value);
  };

  const handleProyectoChange = (e) => {
    setSelectedProyecto(e.value);
  };
  const handleProyectoSelect = (e) => {
    setSelectedProyecto(e.value);
  };

  const handleModalInputChange = (e) => {
    setModalTitle(e.target.value);
  };

  const handleSelecTarea = (e) => {
    setModalTitle(e.value);
  };

  const handleModalDetailsChange = (e) => {
    setModalDetails(e.target.value);
  };

  const handleModalHoursChange = (e) => {
    const value = e.target.value;
    const decimalRegex = /^(\d*\.?\d*)$/;
    if (!decimalRegex.test(value)) {
      setModalHours("");
      return;
    }
    const numValue = parseFloat(value);
    if (!isNaN(numValue) && numValue <= 24 && numValue >= 0) {
      setModalHours(numValue);
    } else {
      setModalHours("");
    }
  };

  const handleFechaFinChange = (e) => {
    const selectedDate = new Date(e.target.value);
    const fechaInicioDate = new Date(fechaInicio);
    if (selectedDate < fechaInicioDate) {
      return;
    } else {
      setFechaFin(e.target.value);
    }
  };

  const handleChangeFecha = (event) => {
    const nuevaFecha = event.target.value;
    setFechaElegida(nuevaFecha);
    const fechaYaExistente = preCarga.some(
      (evento) => evento.fecha === formatDateToReverse(nuevaFecha)
    );
    setAgregarHabilitado(!fechaYaExistente);
  };

  const handlePeriodo = (e) => {
    const nuevoPeriodo = e.target.value;
    setPeriodo(nuevoPeriodo);
    const [mes, año] = nuevoPeriodo.split("-");
    const monthFormatted = mes.padStart(2, "0");
    const nuevaFechaElegida = nuevoPeriodo ? `${año}-${monthFormatted}-01` : "";
    setFechaElegida(nuevaFechaElegida);
  };

  const handleTipoHoraChange = (event) => {
    setTipoHora(event.target.value);
  };

  const handleHorarioChange = (event) => {
    setHorario(event.target.value);
  };

  const chooseOptions = {
    icon: "pi pi-fw pi-paperclip",
    iconOnly: true,
    className: "custom-choose-btn p-button-rounded p-button-outlined",
  };

  // pre carga
  const handlePreCarga = () => {
    const newEvent = {
      id: editingEventDetalleId || new Date().toISOString(),
      title: modalTitle.name,
      titleId: modalTitle._id,
      ccName: selectedCC.name,
      ccId: selectedCC.codigo,
      projectName: selectedProyecto.name,
      detalles: modalDetails,
      start: selectedSlot,
      end: fechaFin,
      // end: dayjs(selectedSlot).add(modalHours, "hours").toDate(),
      horas: modalHours,
      isLicense: false,
      detalle: [
        {
          title: modalTitle.name,
          titleId: modalTitle._id,
          horas: modalHours,
          ccName: selectedCC.name,
          ccId: selectedCC.codigo,
          projectId: selectedProyecto._id,
          projectName: selectedProyecto.name,
          detalles: modalDetails,
          isLicense: false,
        },
      ],
    };

    setPreCarga((prevPreCarga) => [...prevPreCarga, newEvent]);

    // Limpiar campos después de agregar el evento
    setModalTitle("");
    setModalDetails("");
    setModalHours("");
    // setFechaFin("");
    setEsHorasDiarias(true);
    setSugerenciasTareas([]);
  };

  const handlePreCargaLicencias = () => {
    const newEvent = {
      id: editingEventDetalleId || new Date().toISOString(),
      title: modalTitle.name,
      titleId: modalTitle._id,
      ccName: selectedCC.name,
      ccId: selectedCC.codigo,
      detalles: modalDetails,
      start: selectedSlot,
      end: fechaFin,
      isLicense: true,
      detalle: [
        {
          title: modalTitle.name,
          ccName: selectedCC.name,
          inicio: selectedSlot,
          fin: fechaFin,
          detalles: modalDetails,
          isLicense: true,
        },
      ],
    };

    setPreCarga((prevPreCarga) => [...prevPreCarga, newEvent]);

    // Limpiar campos después de agregar el evento
    setModalTitle("");
    setModalDetails("");
    setModalHours("");
    setFechaFin("");
    setEsLicencia(true);
  };

  const handlePreCargaHHEE = () => {
    const newEvent = {
      id: editingEventDetalleId || new Date().toISOString(),
      ccName: selectedCC.name,
      projectName: selectedProyecto.name,
      fecha: formatDateToReverse(fechaElegida),
      periodo: periodo,
      horas: modalHours,
      ccId: selectedCC.codigo,
      tipo: tipoHora.name,
      tipoId: tipoHora.id,
      horario: horario.name,
      horarioId: horario.id,
      start: selectedSlot,
      end: dayjs(selectedSlot).add(modalHours, "hours").toDate(),
      detalle: [
        {
          ccName: selectedCC.name,
          projectName: selectedProyecto.name,
          fecha: fechaElegida,
          horas: modalHours,
          tipo: tipoHora.name,
          horario: horario.name,
          periodo: periodo,
        },
      ],
    };
    setPreCarga((prevPreCarga) => [...prevPreCarga, newEvent]);

    setTipoHora("");
    setModalHours("");
    setHorario("");
    setFechaElegida("");
  };

  // crea los eventos
  const handleAddEvent = async () => {
    try {
      setIsFetching(true);
      const apiUrl = process.env.REACT_APP_URL_TIMESHEET;
      const token = getTokenFromSessionStorage(key);
      let data;
      if (preCarga.some((evento) => evento.isLicense)) {
        data = preCarga.map((evento) => ({
          codigoCeco: evento.ccId,
          projectName: evento.projectName,
          idTask: evento.titleId,
          start: evento.start,
          end: evento.end,
          hours: evento.horas,
        }));
      } else {
        data = preCarga.map((evento) => ({
          codigoCeco: evento.ccId,
          projectName: evento.projectName,
          idTask: evento.titleId,
          detail: evento.detalles,
          start: evento.start,
          end: evento.end,
          hours: evento.horas,
          isOvertime: 0,
        }));
      }

      const options = {
        method: "POST",
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      };
      const response = await fetch(apiUrl + "/api/workdays", options);
      //TODO REDIRECT LOGIN
      if (response.ok) {
        closeModal();
        setIsFetching(false);
        fetchDataGet("/api/workdays/date/" + currentMonth.$d, setEvents);
        fetchData("/api/cecos/hours/" + currentMonth.$d, setHorasPorProyecto);
        toastRef.current.show({
          severity: "success",
          summary: "Evento creado",
          detail: "El evento se ha creado correctamente.",
          life: 5000,
        });
      } else if (response.status === 401) {
        localStorage.clear();
        setUsuario(null);
        setLoadGoogle(false);
        navigate("/login", { replace: true });
        return [];
      } else {
        toastRef.current.show({
          severity: "error",
          summary: "Error al crear el evento",
          detail: "El evento no se pudo crear correctamente.",
          life: 5000,
        });
        setIsFetching(false);
      }
    } catch (error) {
      toastRef.current.show({
        severity: "error",
        summary: "Error al crear el evento",
        detail: "El evento no se pudo crear correctamente.",
        life: 5000,
      });
      setIsFetching(false);
    }
  };

  const handleAddEventHHEE = async () => {
    try {
      const apiUrl = process.env.REACT_APP_URL_TIMESHEET;
      const token = getTokenFromSessionStorage(key);
      const formData = new FormData();

      const datos = preCarga.map((evento) => ({
        codigoCeco: evento.ccId,
        projectName: evento.projectName,
        start: formatDateToReverseMesDiaAño(evento.fecha),
        overtimeType: evento.tipo,
        timePeriod: evento.horario,
        overtimeHours: evento.horas,
      }));

      const datosString = JSON.stringify(datos);
      formData.append("file", archivo[0]);
      formData.append("detail", modalDetails);
      formData.append("isOvertime", true);
      formData.append("overtimeDetail", datosString);

      const options = {
        method: "POST",
        headers: {
          Authorization: "Bearer " + token,
        },
        body: formData,
      };
      const response = await fetch(apiUrl + "/api/workdays/overtime", options);

      if (response.ok) {
        closeModal();
        fetchDataGet("/api/workdays/date/" + currentMonth.$d, setEvents);
        fetchData("/api/cecos/hours/" + currentMonth.$d, setHorasPorProyecto);
        toastRef.current.show({
          severity: "success",
          summary: "Evento creado",
          detail: "El evento se ha creado correctamente.",
          life: 5000,
        });
      } else if (response.status === 401) {
        localStorage.clear();
        setUsuario(null);
        setLoadGoogle(false);
        navigate("/login", { replace: true });
        return [];
      } else {
        toastRef.current.show({
          severity: "error",
          summary: "Error al crear el evento",
          detail: "El evento no se pudo crear correctamente.",
          life: 5000,
        });
      }
    } catch (error) {
      toastRef.current.show({
        severity: "error",
        summary: "Error al crear el evento",
        detail: "El evento no se pudo crear correctamente.",
        life: 5000,
      });
    }
  };

  // funcion para abrir (o no) el form para editar
  const handleCalendarClickEvent = (event) => {
    if (event) {
      const isSummaryEvent = event.data && event.data.x === 10;
      const isHoliday = event?.holiday;
      if (isSummaryEvent || isHoliday) {
        setShowModal(false);
        return;
      }
      setModalTitle("");
      setModalHours("");
      setSelectedCC("");
      setSelectedProyecto(null);
      setModalDetails("");
      setFechaElegida("");
      setEditHabilitado(false);
      setIsModalVerMasOpen(false);
      setEditCarga(false);

      const pre = event.detalle.map((detalle) => ({
        title: detalle.title,
        horas: detalle.horas,
        ccId: detalle.idCeco || detalle.codigoCeco,
        ccName: detalle.ceco,
        projectName: detalle.proyecto,
        idEvento: detalle.id,
        isLicense: detalle.isLicense,
        titleId: detalle.idTitle,
        detalles: detalle.detalles,
        end: detalle.end || event.end,
        start: detalle.start || event.start,
        tipo: detalle.overtimeType,
        horario: detalle.timePeriod,
        fecha: formatDateToReverse(detalle.fecha),
      }));
      setShowModal(true);
      setPreCarga(pre);
      setEditingEventId(pre[0].idEvento);
      const hasHoraExtra = pre.some(
        (detalle) => detalle.title === "Horas Extras"
      );
      setHorasExtras(hasHoraExtra);
      setHorasDiarias(!hasHoraExtra);
      if (pre.some((detalle) => detalle.isLicense)) {
        setEsLicencia(true);
        setEsHorasDiarias(false);
      } else {
        setEsLicencia(false);
        setEsHorasDiarias(true);
      }
    }
  };

  // carga los inputs q estan en disable con los datos a editar
  const editVisualizacion = (event) => {
    setEditHabilitado(true);
    setSelectedCC({
      codigo: event.ccId,
      name: event.ccName,
    });
    setSelectedProyecto({
      id: event.projectId,
      name: event.projectName,
    });
    setModalTitle({
      _id: event.titleId,
      name: event.title,
    });
    setFechaFin(formatDateToISO(event.end));
    setFechaInicio(formatDateToISO(event.start));
    setModalDetails(event.detalles);
    setModalHours(event.horas);
    setTipoHora({
      name: event.tipo,
    });
    setHorario({
      name: event.horario
        ? event.horario.charAt(0).toUpperCase() + event.horario.slice(1).trim()
        : "",
    });
    setEditingEventId(event.id ? event.id : true);
    setFormChanges({
      title: event.titleId,
      details: event.detalles,
      hours: event.horas,
      ccName: event.ccId,
      project: event.projectName,
      fechaFin: formatDateToISO(event.end),
    });
    setEditingEventDetalleId(event.idEvento);
  };

  const handleShowMoreEvents = (date) => {
    const eventsOnSelectedDate = events.filter((event) =>
      dayjs(event.start).isSame(date, "day")
    );
    setIsModalVerMasOpen(true);
    setModalMas(eventsOnSelectedDate);
  };

  const editVisualizacionCarga = (event) => {
    setEditHabilitado(true);
    setModalTitle({
      _id: event.titleId,
      name: event.title,
    });
    setModalDetails(event.detalles);
    setFechaFin(event.end);
    setFechaInicio(formatDateToISO(event.start));
    setSelectedCC({
      codigo: event.ccId,
      name: event.ccName,
    });
    setModalHours(event.horas);
    setEditingEventDetalleId(event.id);
    setEditCarga(true);
    setFormChanges({
      title: event.titleId,
      details: event.detalles,
      hours: event.horas,
      ccName: event.ccId,
      project: event.projectName,
      fechaFin: event.end,
    });
  };

  // funcion para editar los eventos de la vista previa.
  const editarEvento = async () => {
    const existingEvent = preCarga.find(
      (event) => event.idEvento === editingEventDetalleId
    );
    if (existingEvent) {
      setIsFetching(true);
      const eventId = existingEvent.idEvento;
      const apiUrl = `${process.env.REACT_APP_URL_TIMESHEET}/api/workdays/${eventId}`;
      const token = getTokenFromSessionStorage(key);

      const data = {
        idTask: modalTitle._id,
        codigoCeco: selectedCC.codigo,
        projectName: selectedProyecto.name,
        start: existingEvent.start,
        end: existingEvent.isLicense ? fechaFin : existingEvent.end,
        hours: modalHours,
        detail: modalDetails,
        isOvertime: 0,
      };

      try {
        const response = await fetch(apiUrl, {
          method: "PUT",
          headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        });

        if (response.ok) {
          fetchDataGet("/api/workdays/date/" + currentMonth.$d, setEvents);
          fetchData("/api/cecos/hours/" + currentMonth.$d, setHorasPorProyecto);
          toastRef.current.show({
            severity: "success",
            summary: "Evento actualizado",
            detail: "El evento se ha actualizado correctamente.",
            life: 5000,
          });
          setIsFetching(false);
          closeModal();
        } else if (response.status === 401) {
          localStorage.clear();
          setUsuario(null);
          setLoadGoogle(false);
          navigate("/login", { replace: true });
          return [];
        } else {
          toastRef.current.show({
            severity: "error",
            summary: "Error al editar el evento",
            detail: "El evento no se pudo editar correctamente.",
            life: 5000,
          });
          setIsFetching(false);
        }
      } catch (error) {
        toastRef.current.show({
          severity: "error",
          summary: "Error al editar el evento",
          detail: "El evento no se pudo editar correctamente.",
          life: 5000,
        });
        setIsFetching(false);
      }
    }
  };

  const editarCarga = () => {
    const existingEvent = preCarga.find(
      (event) => event.id === editingEventDetalleId
    );
    if (existingEvent) {
      const updatedEvents = preCarga.map((event) =>
        event.id === editingEventDetalleId
          ? {
              ...event,
              titleId: modalTitle._id,
              horas: modalHours,
              detalles: modalDetails,
              ccId: selectedCC.codigo,
              title: modalTitle.name,
              ccName: selectedCC.name,
              end: fechaFin,
            }
          : event
      );

      setPreCarga(updatedEvents);
      setEditingEventId(editingEventId === true ? true : false);
      setModalTitle("");
      setModalDetails("");
      setModalHours("");
      setFechaFin("");
      setSelectedCC("");
      setSelectedProyecto(null);
      setEditCarga(false);
    }
  };

  // abre el modal de eliminar
  const handleDeleteConfirmation = () => {
    setShowDeleteConfirmationModal(true);
    setShowModal(false);
  };

  // funcion para eliminar los eventos de la vista previa, si ya no hay eventos lo borra del calendario.
  const handleDeleteEvent = async () => {
    try {
      setIsFetching(true);
      const apiUrl = process.env.REACT_APP_URL_TIMESHEET;
      const token = getTokenFromSessionStorage(key);

      const options = {
        method: "DELETE",
        headers: {
          Authorization: "Bearer " + token,
        },
      };

      const response = await fetch(
        apiUrl + "/api/workdays/" + deletingEventId,
        options
      );

      if (response.ok) {
        setIsFetching(false);
        closeModal();
        fetchDataGet("/api/workdays/date/" + currentMonth.$d, setEvents);
        fetchData("/api/cecos/hours/" + currentMonth.$d, setHorasPorProyecto);
        toastRef.current.show({
          severity: "success",
          summary: "Evento eliminado",
          detail: "El evento se ha eliminado correctamente.",
          life: 5000,
        });
      } else if (response.status === 401) {
        localStorage.clear();
        setUsuario(null);
        setLoadGoogle(false);
        navigate("/login", { replace: true });
        return [];
      } else {
        toastRef.current.show({
          severity: "error",
          summary: "Error al eliminar el evento",
          detail: "El evento no se pudo eliminar correctamente.",
          life: 5000,
        });
        setIsFetching(false);
      }
    } catch (error) {
      toastRef.current.show({
        severity: "error",
        summary: "Error al eliminar el evento",
        detail: "El evento no se pudo eliminar correctamente.",
        life: 5000,
      });
      setIsFetching(false);
    }
  };

  // funcion para cancelar la edicion de los eventos de la vista previa.
  const cancelarEditCarga = () => {
    setModalTitle("");
    setModalDetails("");
    setModalHours("");
    setSelectedCC("");
    setSelectedProyecto(null);
    setTipoHora("");
    setHorario("");
    setEditCarga(false);
    setFormChanges({ title: "", details: "", hours: 0, fechaFin: "" });
  };

  // borra el evento en la precarga
  const deletePreCarga = (id) => {
    const updatedEvents = preCarga.filter((event) => event.id !== id);
    setPreCarga(updatedEvents);
    setModalTitle("");
    setModalDetails("");
    setModalHours("");
    setEditingEventId(false);
    setEditCarga(false);
    setFechaFin("");
    setTipoHora("");
    setFechaElegida("");
    setHorario("");
    setFormChanges({ title: "", details: "", hours: 0, fechaFin: "" });
  };

  // cirra el modal
  const closeModal = () => {
    setShowModal(false);
    setShowDeleteConfirmationModal(false);
    setModalTitle("");
    setModalDetails("");
    setModalHours("");
    setSelectedCC("");
    setSelectedProyecto(null);
    setTipoHora("");
    setHorario("");
    setSelectedSlot(null);
    setPeriodo("");
    setEditingEventId(null);
    setDeletingEventId(null);
    setFormChanges({
      title: "",
      details: "",
      hours: 0,
      ccName: "",
      project: "",
      fechaFin: "",
    });
    setPreCarga([]);
    setEditCarga(false);
    setHorasExtras(false);
    setHorasDiarias(true);
    setEsLicencia(false);
    setEsHorasDiarias(false);
    setFechaElegida("");
    setArchivo([]);
    setAgregarHabilitado(true);
  };

  const cerrarVerMas = () => {
    setIsModalVerMasOpen(false);
    setModalMas([]);
  };

  // cambia el idioma del calendario
  const customMessages = {
    allDay: "Todo el día",
    previous: "Anterior",
    next: "Siguiente",
    today: "Hoy",
    month: "Mes",
    week: "Semana",
    day: "Día",
    agenda: "Agenda",
    date: "Fecha",
    time: "Hora",
    event: "Evento",
    showMore: (total) => `+${total} tareas`,
  };

  // guarda las horas de cada evento en un objeto llamado hoursPerDay
  const sumHoursPerDay = () => {
    const hoursPerDay = {};

    events.forEach((event) => {
      const dayKey = dayjs(event.start).format("YYYY-MM-DD");
      const currentHours = hoursPerDay[dayKey] || 0;

      hoursPerDay[dayKey] = currentHours + event.horas;
    });

    return hoursPerDay;
  };

  const hoursPerDay = sumHoursPerDay();

  // crea los eventos para mostrar las horas totales de cada dia
  const createSummaryEvents = () => {
    const summaryEvents = [];

    for (const dayKey in hoursPerDay) {
      const hasLicenseEvent = events.some(
        (event) =>
          dayjs(event.start).format("YYYY-MM-DD") === dayKey &&
          event.isLicense === true
      );

      if (!hasLicenseEvent) {
        const totalHours = hoursPerDay[dayKey];

        const summaryEvent = {
          title: `${totalHours}hs`,
          start: dayjs(dayKey).toDate(),
          end: dayjs(dayKey).add(1, "day").toDate(),
          allDay: true,
          data: { x: 10 },
        };

        summaryEvents.push(summaryEvent);
      }
    }

    return summaryEvents;
  };

  const summaryEvents = createSummaryEvents();

  useEffect(() => {
    if (
      selectTarea.find((tarea) => tarea.id === modalTitle)?.isLicense !==
        true &&
      !editingEventId &&
      !editCarga
    ) {
      setModalHours("");
      //setFechaFin("");
    }
  }, [modalTitle, selectTarea]);

  //toolBar donde se escuentran los botones para cambiar de mes, el mes actual y las horas totales del mes
  // eslint-disable-next-line react/prop-types
  const CustomToolbar = ({ onNavigate }) => {
    useEffect(() => {
      const totalHours = horasPorProyecto.reduce(
        (total, obj) => total + obj.hours,
        0
      );
      setTotalHoursLoaded(totalHours || 0);
    }, []);

    const handleMonthChange = (event) => {
      const newMonthValue = event.target.value;
      if (newMonthValue) {
        const newMonth = dayjs(newMonthValue);
        setCurrentMonth(newMonth);
        onNavigate("DATE", newMonth.toDate());
        setIsModalVerMasOpen(false);
        setShowModal(false);
      }
    };
    const newLocal = (
      <Tooltip
        id="tooltip"
        style={{
          color: "rgb(18, 81, 239)",
          backgroundColor: "rgb(218, 215, 215)",
          fontSize: "18px",
          fontWeight: "600",
          zIndex: "9999 !important",
        }}
      ></Tooltip>
    );
    const tooltipHtml = horasPorProyecto
      .map((obj) => {
        let nameHours = `${obj.name}: ${obj.hours} hs`;
        return `<article>${nameHours}<br/></article>`;
      })
      .join("");

    return (
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div style={{ display: "flex", gap: "2rem" }}>
          <input
            type="month"
            value={currentMonth.format("YYYY-MM")}
            style={{
              textTransform: "capitalize",
            }}
            className="input-calendar-month"
            onChange={handleMonthChange}
          />
          <ReporteUsuario
            formatPeriodo={currentMonth.format("MM-YYYY")}
            showToast={showToast}
          />
        </div>
        <div></div>
        <div className="total-horas">
          {(usuario.EsAdministrador ||
            usuario.Rol === "servicios" ||
            usuario.Rol === "admin" ||
            usuario.Rol === "timesheetReport") && (
            <div style={{ paddingRight: "30px", height: "30px" }}>
              <button
                className="reporte"
                onClick={() => {
                  setIsOpenModalReporte(true);
                  setShowModal(false);
                }}
              >
                Descarga de reporte
              </button>
            </div>
          )}

          {
            <div style={{ marginRight: "30px", height: "33px" }}>
              <button
                className="buttons-switch-calendar"
                onClick={() => {
                  setShowModal(true);
                  setHorasExtras(true);
                  setHorasDiarias(false);
                  setFechaElegida("");
                  setAgregarHabilitado(true);
                  setPreCarga([]);
                  setEditingEventId(false);
                }}
              >
                Horas extra
              </button>
            </div>
          }

          <div className="tooltip-calendar">
            <a
              data-tooltip-id="tooltip"
              data-tooltip-place="bottom"
              data-multiline="true"
              data-tooltip-html={tooltipHtml}
              data-tooltip-class-name="viewHsTooltip"
              style={{ display: "flex" }}
            >
              <>
                <span
                  className="material-symbols-outlined eye"
                  style={{ fontSize: "23px" }}
                >
                  visibility
                </span>
              </>
            </a>
            {newLocal}
          </div>
          <p
            style={{
              fontSize: "19px",
              fontWeight: "600",
              color: "rgb(18 81 239)",
            }}
          >
            Horas cargadas: { 
              Number.isInteger(totalHoursLoaded) 
              ? totalHoursLoaded.toString().slice(0, 6)
              : totalHoursLoaded.toFixed(3)
            }
          </p>
        </div>
      </div>
    );
  };

  const fecha = selectedSlot
    ? `${selectedSlot.getDate().toString().padStart(2, "0")}/${(
        selectedSlot.getMonth() + 1
      )
        .toString()
        .padStart(2, "0")}/${selectedSlot.getFullYear()}`
    : "";

  const monthFormatted = periodo.split("-")[0].padStart(2, "0");

  const tareasFiltradas =
    preCarga.length === 0
      ? selectTarea
      : selectTarea.filter((tarea) => {
          if (esLicencia) {
            return tarea.isLicense === true;
          } else if (esHorasDiarias) {
            return tarea.isLicense === false;
          }
          return true;
        });

  const components = {
    event: (props) => {
      // eslint-disable-next-line react/prop-types
      const isSummaryEvent = props.event.data && props.event.data.x === 10;
      let justifyContent;
      let fontSize;
      let color;
      let borderBottom;
      let backgroundColor;
      let borderRadius;
      let padding;
      let paddingLeft;
      if (isSummaryEvent) {
        justifyContent = "center";
        fontSize = "16px";
        color = "black";
        borderBottom = "1px solid black";
        backgroundColor = "";
      } else if (props.event.isLicense) {
        backgroundColor = "#F4511E";
        borderRadius = "4px";
        paddingLeft = "5px";
      } else if (props.event.holiday) {
        backgroundColor = "purple";
        borderRadius = "4px";
        fontSize = "16px";
        padding = "2px";
      }

      const eventStyle = {
        display: "flex",
        justifyContent,
        fontSize,
        color,
        borderBottom,
        backgroundColor,
        borderRadius,
        padding,
        paddingLeft,
      };

      return <div style={eventStyle}>{props.title}</div>;
    },
    toolbar: CustomToolbar,
  };

  const searchCC = (event) => {
    const items = centroDeCostos.filter((item) =>
      item.name.toLowerCase().includes(event.query.toLowerCase())
    );
    setFilteredCC(items);
  };

  const handleCCBlur = () => {
    if (!filteredCC.some((item) => item.name === selectedCC.name)) {
      setSelectedCC("");
      setSelectedProyecto(null);
    }
  };

  const searchProyectos = (event) => {
    const items = proyectos.filter((item) =>
      item.name.toLowerCase().includes(event.query.toLowerCase())
    );
    setFilteredProyectos(items);
  };

  const handleProyectoBlur = () => {
    if (
      !filteredProyectos.some((item) => item?.name === selectedProyecto?.name)
    ) {
      setSelectedProyecto(null);
    }
  };

  const [sugerenciasTareas, setSugerenciasTareas] = useState([]);
  const searchTareas = (event) => {
    const tareaFiltradas = tareasFiltradas.filter((tarea) =>
      tarea.name.toLowerCase().includes(event.query.toLowerCase())
    );
    const sugerencias = tareaFiltradas.map((tarea) => ({
      name: tarea.name,
      _id: tarea._id,
    }));
    setSugerenciasTareas(sugerencias);
  };

  const handleBlurTarea = () => {
    if (
      !sugerenciasTareas.some(
        (sugerencia) => sugerencia.name === modalTitle.name
      )
    ) {
      setModalTitle("");
    }
  };

  const isDataValid = (fechaFinParam) => {
    const date = new Date(fechaFinParam);
    return !isNaN(date.getTime());
  };

  const showToast = (toastConfig) => {
    toastRef.current.show(toastConfig);
  };

  const isTareaDeHoras = () => {
    const value = selectTarea.find(
      (tarea) => tarea._id === modalTitle._id
    )?.isLicense;
    return value ? false : true;
  };

  return (
    <div style={{ width: "100%", display: "flex" }}>
      <Toast ref={toastRef} />
      <div style={{ height: "94vh", width: "100%" }}>
        <Calendar
          localizer={localizer}
          dayFormat={"dddd"}
          views={["month"]}
          onSelectSlot={handleCalendarClickSlot}
          events={events.concat(summaryEvents).concat(holidays)}
          selectable={true}
          onSelectEvent={handleCalendarClickEvent}
          messages={customMessages}
          components={components}
          drilldownView={null}
          onDrillDown={handleShowMoreEvents}
        />
      </div>
      {showModal && (
        <div className="modal-calendar">
          <span
            className="material-symbols-outlined"
            style={{
              cursor: "pointer",
              fontSize: "24px",
              display: "flex",
              justifyContent: "flex-end",
            }}
            onClick={closeModal}
          >
            close
          </span>

          <div>
            <div style={{ marginBottom: "20px" }}>
              <h3
                style={{
                  display: "flex",
                  fontFamily: "inherit",
                  fontSize: "17px",
                }}
              >
                {horasExtras
                  ? `Carga de horas extra`
                  : editingEventId
                  ? "Editar tarea"
                  : `Carga de horas: ${fecha}`}
              </h3>
            </div>

            {horasDiarias && (
              <>
                <div className="">
                  <p
                    style={{
                      margin: 0,
                      display: "flex",
                      justifyContent: "flex-start",
                      marginTop: "30px",
                    }}
                  >
                    Centro de costo*
                  </p>
                  <AutoComplete
                    value={selectedCC}
                    suggestions={filteredCC}
                    completeMethod={searchCC}
                    field="name"
                    className="select-proyecto-calendar"
                    dropdown
                    onChange={handleCCChange}
                    onSelect={handleCCSelect}
                    onBlur={handleCCBlur}
                    placeholder="Selecciona el centro de costo"
                    disabled={editingEventId && !editHabilitado}
                  />
                </div>
                <div style={{ width: "100%" }}>
                  <p
                    style={{
                      margin: 0,
                      display: "flex",
                      justifyContent: "flex-start",
                    }}
                  >
                    Proyecto*
                  </p>
                  <AutoComplete
                    value={selectedProyecto}
                    suggestions={filteredProyectos}
                    completeMethod={searchProyectos}
                    field="name"
                    className="select-proyecto-calendar"
                    dropdown
                    onChange={handleProyectoChange}
                    onSelect={handleProyectoSelect}
                    onBlur={handleProyectoBlur}
                    placeholder="Selecciona el proyecto"
                    disabled={editingEventId && !editHabilitado}
                  />
                </div>
                <div>
                  <div style={{ width: "100%" }}>
                    <p
                      style={{
                        margin: 0,
                        display: "flex",
                        justifyContent: "flex-start",
                      }}
                    >
                      Tarea*
                    </p>
                    <AutoComplete
                      value={modalTitle}
                      suggestions={sugerenciasTareas}
                      completeMethod={searchTareas}
                      field="name"
                      className="select-proyecto-calendar"
                      dropdown
                      onChange={handleModalInputChange}
                      onSelect={handleSelecTarea}
                      onBlur={handleBlurTarea}
                      placeholder="Selecciona la tarea"
                      disabled={editingEventId && !editHabilitado}
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                    }}
                  >
                    <div style={{ width: "100%", paddingRight: "6px" }}>
                      <p
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                          margin: 0,
                        }}
                      >
                        Fecha inicio
                      </p>
                      <input
                        type="date"
                        className="input-horas-calendar"
                        disabled
                        value={fechaInicio}
                      />
                    </div>
                    <div style={{ width: "100%" }}>
                      <p
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                          margin: 0,
                        }}
                      >
                        Fecha fin*
                      </p>
                      <input
                        type="date"
                        className="input-horas-calendar"
                        min={fechaInicio}
                        value={fechaFin}
                        onChange={handleFechaFinChange}
                      />
                    </div>
                  </div>
                  {isTareaDeHoras() && (
                    <>
                      <div style={{ width: "100%" }}>
                        <p
                          style={{
                            margin: 0,
                            display: "flex",
                            justifyContent: "flex-start",
                          }}
                        >
                          Horas*
                        </p>
                        <InputText
                          type="number"
                          value={modalHours}
                          className="select-proyecto-calendar"
                          onChange={handleModalHoursChange}
                          placeholder="Escribi la cantidad de horas"
                          min={0}
                          max={24}
                          disabled={editingEventId && !editHabilitado}
                        />
                      </div>
                    </>
                  )}
                </div>
                <div>
                  <p
                    style={{
                      margin: 0,
                      display: "flex",
                      justifyContent: "flex-start",
                      marginTop: "20px",
                    }}
                  >
                    Detalle
                  </p>
                  <InputTextarea
                    value={modalDetails}
                    onChange={handleModalDetailsChange}
                    maxLength={200}
                    placeholder="Escribi el detalle de la tarea"
                    className="text-area-detalles-calendar "
                    disabled={editingEventId && !editHabilitado}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    marginTop: "25px",
                  }}
                >
                  {editCarga && (
                    <button
                      className="button-modal-calendar"
                      style={{ background: "#e62828" }}
                      onClick={cancelarEditCarga}
                    >
                      cancelar
                    </button>
                  )}
                  <button
                    onClick={() => {
                      if (editingEventId) {
                        editarEvento();
                      } else if (editCarga) {
                        editarCarga();
                      } else if (isTareaDeHoras()) {
                        handlePreCarga();
                      } else {
                        handlePreCargaLicencias();
                      }
                    }}
                    className="button-modal-calendar"
                    style={{ marginLeft: "20px" }}
                    disabled={
                      isFetching ||
                      modalTitle === "" ||
                      (!isDataValid(fechaFin) &&
                        (Number(modalHours) <= 0 ||
                          modalHours === undefined)) ||
                      selectedCC === "" ||
                      (formChanges.title === modalTitle._id &&
                        (formChanges.hours === modalHours || modalHours <= 0) &&
                        formChanges.details === modalDetails &&
                        formChanges.ccName === selectedCC.codigo &&
                        formChanges.project === selectedProyecto.name &&
                        formChanges.fechaFin === fechaFin)
                    }
                  >
                    {editingEventId || editCarga ? "Editar" : "agregar"}
                  </button>
                </div>
                <div>
                  {preCarga.length > 0 &&
                    preCarga.some((evento) => evento.isLicense === false) && (
                      <div style={{ marginTop: "17px" }}>
                        <div style={{ maxHeight: "230px", overflow: "auto" }}>
                          <h4 style={{ marginBottom: "5px" }}>Vista previa:</h4>
                          <table
                            style={{
                              width: "100%",
                              border: "1px solid #ced4da",
                              borderRadius: "5px",
                              padding: "2px",
                            }}
                          >
                            <thead>
                              <tr className="tr-calendar">
                                <th
                                  style={{
                                    textAlign: "justify",
                                    width: "100px",
                                  }}
                                >
                                  Proyecto
                                </th>
                                <th>Tarea</th>
                                <th>Horas</th>
                                <th></th>
                                <th></th>
                              </tr>
                            </thead>
                            <tbody>
                              {preCarga
                                .filter((evento) => evento.isLicense === false)
                                .map((event, index) => (
                                  <tr
                                    key={index}
                                    style={{
                                      height: "37px",
                                      textAlign: "center",
                                    }}
                                  >
                                    <td style={{ textAlign: "justify" }}>
                                      <td style={{ textAlign: "justify" }}>
                                        {event.projectName}
                                      </td>
                                    </td>
                                    <td>{event.title}</td>
                                    <td>{event.horas}</td>
                                    <td>
                                      <span
                                        className="material-symbols-outlined"
                                        style={{
                                          color: "blue",
                                          fontSize: "18px",
                                          cursor: "pointer",
                                        }}
                                        onClick={() => {
                                          if (editingEventId) {
                                            editVisualizacion(event);
                                          } else {
                                            editVisualizacionCarga(event);
                                          }
                                        }}
                                      >
                                        edit
                                      </span>
                                    </td>
                                    <td className="td-calendar">
                                      <span
                                        style={{
                                          color: "red",
                                          cursor: "pointer",
                                          fontSize: "18px",
                                        }}
                                        onClick={() => {
                                          if (editingEventId) {
                                            handleDeleteConfirmation();
                                            setDeletingEventId(event.idEvento);
                                          } else {
                                            deletePreCarga(event.id);
                                          }
                                        }}
                                      >
                                        <MdDelete />
                                      </span>
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                        </div>
                        {!editingEventId ? (
                          <button
                            className="button-modal-calendar"
                            style={{
                              justifyContent: "center",
                              width: "100%",
                              marginTop: "20px",
                            }}
                            disabled={isFetching}
                            onClick={handleAddEvent}
                          >
                            Guardar
                          </button>
                        ) : null}
                      </div>
                    )}

                  {preCarga.length > 0 &&
                    preCarga.some((evento) => evento.isLicense === true) && (
                      <div style={{ marginTop: "17px" }}>
                        <div style={{ maxHeight: "230px", overflow: "auto" }}>
                          <h4 style={{ marginBottom: "5px" }}>Vista previa:</h4>
                          <table
                            style={{
                              width: "100%",
                              border: "1px solid #ced4da",
                              borderRadius: "5px",
                              padding: "2px",
                            }}
                          >
                            <thead>
                              <tr className="tr-calendar">
                                <th
                                  style={{
                                    textAlign: "justify",
                                    width: "100px",
                                  }}
                                >
                                  Proyecto
                                </th>
                                <th>Tarea</th>
                                <th>Inicio</th>
                                <th>Fin</th>
                                <th></th>
                                <th></th>
                              </tr>
                            </thead>
                            <tbody>
                              {preCarga
                                .filter((evento) => evento.isLicense === true)
                                .map((event, index) => (
                                  <tr
                                    key={index}
                                    style={{
                                      height: "37px",
                                      textAlign: "center",
                                    }}
                                  >
                                    <td style={{ textAlign: "justify" }}>
                                      <td style={{ textAlign: "justify" }}>
                                        {
                                          (event.ccName?.match(/- (.*) -/) || [
                                            "",
                                            event.ccName,
                                          ])[1]
                                        }
                                      </td>
                                    </td>
                                    <td>{event.title}</td>
                                    <td style={{ paddingRight: "5px" }}>
                                      {formatDateToReverse(event.start)}
                                    </td>
                                    <td style={{ paddingRight: "5px" }}>
                                      {formatDateToReverse(event.end)}
                                    </td>
                                    <td>
                                      <span
                                        className="material-symbols-outlined"
                                        style={{
                                          color: "blue",
                                          fontSize: "18px",
                                          cursor: "pointer",
                                        }}
                                        onClick={() => {
                                          if (editingEventId) {
                                            editVisualizacion(event);
                                          } else {
                                            editVisualizacionCarga(event);
                                          }
                                        }}
                                      >
                                        edit
                                      </span>
                                    </td>
                                    <td className="td-calendar">
                                      <span
                                        style={{
                                          color: "red",
                                          cursor: "pointer",
                                          fontSize: "18px",
                                        }}
                                        onClick={() => {
                                          if (editingEventId) {
                                            handleDeleteConfirmation();
                                            setDeletingEventId(event.idEvento);
                                          } else {
                                            deletePreCarga(event.id);
                                          }
                                        }}
                                      >
                                        <MdDelete />
                                      </span>
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                        </div>
                        {!editingEventId ? (
                          <button
                            className="button-modal-calendar"
                            style={{
                              justifyContent: "center",
                              width: "100%",
                              marginTop: "20px",
                            }}
                            disabled={isFetching}
                            onClick={handleAddEvent}
                          >
                            Guardar
                          </button>
                        ) : null}
                      </div>
                    )}
                </div>
              </>
            )}

            {horasExtras && (
              <>
                <div className="">
                  <p
                    style={{
                      margin: 0,
                      display: "flex",
                      justifyContent: "flex-start",
                      marginTop: "30px",
                    }}
                  >
                    Centro de costo*
                  </p>
                  <AutoComplete
                    value={selectedCC}
                    suggestions={filteredCC}
                    completeMethod={searchCC}
                    field="name"
                    className="select-proyecto-calendar"
                    dropdown
                    onChange={handleCCChange}
                    onSelect={handleCCSelect}
                    onBlur={handleCCBlur}
                    placeholder="Selecciona el centro de costo"
                    disabled={editingEventId && !editHabilitado}
                  />
                </div>

                <div style={{ width: "100%" }}>
                  <p
                    style={{
                      margin: 0,
                      display: "flex",
                      justifyContent: "flex-start",
                    }}
                  >
                    Proyecto*
                  </p>
                  <AutoComplete
                    value={selectedProyecto}
                    suggestions={filteredProyectos}
                    completeMethod={searchProyectos}
                    field="name"
                    className="select-proyecto-calendar"
                    dropdown
                    onChange={handleProyectoChange}
                    onSelect={handleProyectoSelect}
                    onBlur={handleProyectoBlur}
                    placeholder="Selecciona el proyecto"
                    disabled={editingEventId && !editHabilitado}
                  />
                </div>

                <div
                  style={{
                    display: "flex",
                  }}
                >
                  <div style={{ width: "100%", paddingRight: "6px" }}>
                    <p
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        margin: 0,
                      }}
                    >
                      Periodo*
                    </p>
                    <Dropdown
                      value={periodo}
                      onChange={handlePeriodo}
                      options={periodos}
                      id="periodo"
                      name="periodo"
                      placeholder="Seleccionar tipo"
                      checkmark={true}
                      highlightOnSelect={false}
                      style={{ width: "100%" }}
                      disabled={editingEventId && !editHabilitado}
                    />
                  </div>
                  <div style={{ width: "100%", paddingRight: "6px" }}>
                    <p
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        margin: 0,
                      }}
                    >
                      Fecha*
                      <span style={{ color: "red", fontSize: "14px" }}>
                        {!agregarHabilitado && " - *Ya cargaste esta fecha*"}
                      </span>
                    </p>

                    <input
                      type="date"
                      className="input-horas-calendar"
                      style={{
                        marginTop: "0px",
                        fontSize: "14px",
                        borderRadius: "6px",
                        border: "0.5px solid #ced4da",
                      }}
                      value={fechaElegida}
                      min={
                        periodo
                          ? `${periodo.split("-")[1]}-${monthFormatted}-01`
                          : ""
                      }
                      max={
                        periodo
                          ? `${
                              periodo.split("-")[1]
                            }-${monthFormatted}-${new Date(
                              periodo.split("-")[1],
                              monthFormatted,
                              0
                            ).getDate()}`
                          : ""
                      }
                      onChange={handleChangeFecha}
                      disabled={
                        (editingEventId && !editHabilitado) || periodo === ""
                      }
                    />
                  </div>
                </div>
                <div style={{ display: "flex", marginTop: "20px" }}>
                  <div style={{ width: "100%", paddingRight: "6px" }}>
                    <p
                      style={{
                        margin: 0,
                        display: "flex",
                        justifyContent: "flex-start",
                      }}
                    >
                      Cantidad*
                    </p>
                    <InputText
                      type="number"
                      value={modalHours}
                      className="select-proyecto-calendar"
                      onChange={handleModalHoursChange}
                      placeholder="cantidad de horas"
                      min={0}
                      max={24}
                      disabled={editingEventId && !editHabilitado}
                    />
                  </div>
                  <div style={{ width: "100%", paddingRight: "6px" }}>
                    <p
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        margin: 0,
                      }}
                    >
                      Tipo*
                    </p>
                    <Dropdown
                      value={tipoHora}
                      onChange={handleTipoHoraChange}
                      options={selectTipo}
                      optionLabel="name"
                      placeholder="Seleccionar tipo"
                      checkmark={true}
                      highlightOnSelect={false}
                      style={{ width: "100%" }}
                      disabled={editingEventId && !editHabilitado}
                    />
                  </div>
                  <div style={{ width: "100%" }}>
                    <p
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        margin: 0,
                      }}
                    >
                      Horario*
                    </p>
                    <Dropdown
                      value={horario}
                      onChange={handleHorarioChange}
                      options={selectHorario}
                      optionLabel="name"
                      placeholder="Seleccionar horario"
                      checkmark={true}
                      highlightOnSelect={false}
                      style={{ width: "100%" }}
                      disabled={editingEventId && !editHabilitado}
                    />
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    marginTop: "25px",
                  }}
                >
                  {editCarga && (
                    <button
                      className="button-modal-calendar"
                      style={{ background: "#e62828" }}
                      onClick={cancelarEditCarga}
                    >
                      cancelar
                    </button>
                  )}
                  <button
                    onClick={() => {
                      handlePreCargaHHEE();
                    }}
                    className="button-modal-calendar"
                    style={{ marginLeft: "20px" }}
                    disabled={
                      selectedCC === "" ||
                      tipoHora === "" ||
                      horario === "" ||
                      fechaElegida === "" ||
                      modalHours <= 0 ||
                      !agregarHabilitado
                    }
                  >
                    {editingEventId || editCarga ? "Editar" : "agregar"}
                  </button>
                </div>

                <div>
                  {preCarga.length > 0 &&
                    preCarga.some(
                      (evento) =>
                        evento.horario || evento.title === "Horas Extras"
                    ) && (
                      <div style={{ marginTop: "8px" }}>
                        <div style={{ maxHeight: "230px", overflow: "auto" }}>
                          <h4 style={{ marginBottom: "5px" }}>Vista previa:</h4>
                          <table
                            style={{
                              width: "100%",
                              border: "1px solid #ced4da",
                              borderRadius: "5px",
                              padding: "2px",
                            }}
                          >
                            <thead>
                              <tr className="tr-calendar">
                                <th
                                  style={{
                                    width: "80px",
                                    textAlign: "justify",
                                  }}
                                >
                                  Fecha
                                </th>
                                <th>Proyecto</th>
                                <th>Tipo</th>
                                <th>Horario</th>
                                <th>Cantidad</th>
                                {!editingEventId && (
                                  <>
                                    <th></th>
                                  </>
                                )}
                              </tr>
                            </thead>
                            <tbody>
                              {preCarga
                                .filter(
                                  (evento) =>
                                    evento.horario !== "" ||
                                    evento.title === "Horas Extras"
                                )
                                .map((event, index) => (
                                  <tr
                                    key={index}
                                    style={{
                                      height: "37px",
                                      textAlign: "center",
                                    }}
                                  >
                                    <td>{event.fecha ? event.fecha : null}</td>
                                    <td>
                                      <td style={{ textAlign: "center" }}>
                                        {event.projectName}
                                      </td>
                                    </td>
                                    <td>{event.tipo}</td>
                                    <td>{event.horario}</td>
                                    <td>{event.horas}</td>
                                    {!editingEventId && (
                                      <>
                                        <td className="td-calendar">
                                          <span
                                            style={{
                                              color: "red",
                                              cursor: "pointer",
                                              fontSize: "18px",
                                            }}
                                            onClick={() =>
                                              deletePreCarga(event.id)
                                            }
                                          >
                                            <MdDelete />
                                          </span>
                                        </td>
                                      </>
                                    )}
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    )}
                </div>
                <div>
                  <p
                    style={{
                      margin: 0,
                      display: "flex",
                      justifyContent: "flex-start",
                      marginTop: "15px",
                    }}
                  >
                    Comentarios
                  </p>
                  <InputTextarea
                    value={modalDetails}
                    onChange={handleModalDetailsChange}
                    maxLength={200}
                    className="text-area-detalles-calendar"
                    disabled={editingEventId && !editHabilitado}
                  />
                </div>
                <div style={{ width: "100%", marginTop: "15px" }}>
                  <FileUpload
                    style={{
                      paddingRight: 15,
                    }}
                    chooseOptions={chooseOptions}
                    auto
                    name="fileUploadControl"
                    customUpload
                    uploadHandler={(e) => setArchivo(e.files)}
                    multiple
                    accept="image/*,.pdf,.xls,.xlsx,.doc,.docx"
                    maxFileSize={tamañoMaxArchivo}
                    emptyTemplate={
                      <p className="m-0">{`Arrastre o examine archivos. (tamaño máximo ${
                        tamañoMaxArchivo / 1000 / 1000
                      } MB)`}</p>
                    }
                    invalidFileSizeMessageSummary="Error! "
                    invalidFileSizeMessageDetail={`El tamaño del archivo a adjuntar supera el máximo permitido.`}
                  />
                </div>
                {!editingEventId ? (
                  <button
                    className="button-modal-calendar"
                    style={{
                      justifyContent: "center",
                      width: "100%",
                      marginTop: "20px",
                    }}
                    onClick={handleAddEventHHEE}
                    disabled={preCarga.length === 0}
                  >
                    Guardar
                  </button>
                ) : null}
              </>
            )}
          </div>
        </div>
      )}

      {isModalVerMasOpen && (
        <div className="mini-modal">
          <span
            className="material-symbols-outlined"
            style={{
              cursor: "pointer",
              fontSize: "19px",
              display: "flex",
              justifyContent: "flex-end",
            }}
            onClick={cerrarVerMas}
          >
            close
          </span>

          <h2>
            {modalMas.length > 0
              ? dayjs(modalMas[0].start).locale("es").format("dddd")
              : "Eventos"}
          </h2>
          <p>
            {modalMas.length > 0 ? dayjs(modalMas[0].start).format("D") : ""}
          </p>
          <ul>
            {modalMas.map((event) => (
              <li key={event.id} onClick={() => handleCalendarClickEvent(event)}>
                {event.title}
              </li>
            ))}
          </ul>
        </div>
      )}

      {showDeleteConfirmationModal && (
        <div className="overlay">
          <div className="modal-eliminar-calendar">
            <span
              className="material-symbols-outlined"
              style={{
                cursor: "pointer",
                fontSize: "20px",
                display: "flex",
                justifyContent: "flex-end",
              }}
              onClick={() => {
                setShowDeleteConfirmationModal(false);
                setShowModal(true);
              }}
            >
              close
            </span>
            <div>
              <h2
                style={{
                  fontSize: "23px",
                  padding: "0px 0px 8px 8px",
                  color: "rgb(18, 81, 239)",
                }}
              >
                Confirmar
              </h2>
              <p style={{ textAlign: "start", padding: "0px 0px 8px 8px" }}>
                ¿Seguro que quieres eliminar este evento?
              </p>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "15px",
                }}
              >
                <span
                  onClick={() => {
                    setShowDeleteConfirmationModal(false);
                    setShowModal(true);
                  }}
                  className="button-no-calendar"
                >
                  Cancelar
                </span>
                <span
                  className="button-si-calendar"
                  onClick={() => handleDeleteEvent(deletingEventId)}
                  disabled={isFetching}
                >
                  Eliminar
                </span>
              </div>
            </div>
          </div>
        </div>
      )}

      {isOpenModaleReporte && (
        <ModalReporte
          selectProyecto={centroDeCostos}
          cancel={setIsOpenModalReporte}
          showToast={showToast}
        />
      )}
    </div>
  );
};

export default Calendario;
