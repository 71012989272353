import { useState, useRef, useContext, useEffect } from "react";
import Menu from "../../components/Menu";
import logoitp from "../../imagenes/logo.png";

import SpeedDialCustom from "../../components/SpeedDialCustom";
import { Toast } from "primereact/toast";
import TableUsuariosExternos from "./TableUsuariosExternos";
import FormUsuarioExterno from "./FormUsuarioExterno";
import { NovedadesContext } from "../../contexts/NovedadesContext";
import { AuthContext } from "../../contexts/AuthContext";
import { Button } from "primereact/button";
import { fechaHora } from "../../helpers";

const PanelUsuariosExternos = () => {
  const [displayUserModal, setDisplayUserModal] = useState(false);
  const [usuariosExternosFiltrados, setUsuariosExternosFiltrados] = useState([]);

  const {
    getUsuariosExternos,
    usuariosExternos,
    cambioNovedad,
    loading,
   // loadingExcel,
    getExcelFreelance,
  } = useContext(NovedadesContext);
  const { usuario } = useContext(AuthContext);
  const toast = useRef(null);

  useEffect(() => {
    const handleGetUsuariosExternos = async () => {
      await getUsuariosExternos();
    };
    handleGetUsuariosExternos();
  }, [cambioNovedad]);

  // const handleClick = async () => {
  //   const blob = await getExcelFreelance();
  //   if (blob) {
  //     const urlArchivo = URL.createObjectURL(blob);
  //     const linkDescarga = document.createElement("a");
  //     linkDescarga.href = urlArchivo;
  //     linkDescarga.download =
  //       "Informe freelance" +
  //       " _ " +
  //       fechaHora().fecha +
  //       " _ " +
  //       fechaHora().hora;
  //     linkDescarga.click();
  //     URL.revokeObjectURL(urlArchivo);
  //   }
  // };

  const dialogFuncMap = {
    FormUsuarioExterno: setDisplayUserModal,
  };

  const speedDialClick = (name, position) => {
    dialogFuncMap[`${name}`](true);
  };

  return (
    <div className="container">
      <Toast ref={toast} />
      <Menu />
      <div className="encabezado">
        <h3 className="titulo">Portal de novedades</h3>{" "}
        <img className="imagen_encabezado" src={logoitp} alt="" />
      </div>
      <div className="titulo_novedades">
        <div>
          <h3 className="">Panel Usuarios Externos</h3>
          {(usuario.Rol !== "po" && usuario.Rol !== "poFull") && (
            <SpeedDialCustom speedDialClick={speedDialClick} tipoNovedad={5} />
          )}
        </div>
        {/* <div className="boton-informe-freelance">
          <Button
            disabled={loadingExcel}
            className="p-button1"
            label="Descargar Excel"
            onClick={handleClick}
            style={{ padding: "3px", maxHeight: "50px" }}
            icon={
              loadingExcel ? (
                <i
                  className="pi pi-spin pi-spinner"
                  style={{ fontSize: "1rem" }}
                ></i>
              ) : (
                "pi pi-file-excel"
              )
            }
          />
        </div> */}
      </div>
      <TableUsuariosExternos data={usuariosExternos} loading={loading} />
      <FormUsuarioExterno
        registroId={0}
        setDisplayModal={setDisplayUserModal}
        displayModal={displayUserModal}
      />
    </div>
  );
};

export default PanelUsuariosExternos;
