import { fechaHora } from ".";

const downloadFileFromBlob = (dataBlob, fileName) => {
  if (dataBlob) {
    const fileUrl = URL.createObjectURL(dataBlob);
    const downloadLink = document.createElement("a");
    downloadLink.href = fileUrl;
    downloadLink.download =
      "portal-" +
      fileName +
      "-" +
      fechaHora().fecha +
      "-" +
      fechaHora().hora.replace(/:/g, "-");
    downloadLink.click();
    URL.revokeObjectURL(fileUrl);
  }
};

export { downloadFileFromBlob };
